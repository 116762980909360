/** @jsx jsx */
import React, { useRef, useCallback, memo, type ReactElement } from 'react';
import { css, jsx } from '@compiled/react';
import WarningIcon from '@atlaskit/icon/core/error';
import InlineDialog from '@atlaskit/inline-dialog';
import InlineEdit from '@atlaskit/inline-edit';
import { Box, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	defaultValue: string;
	isEditing: boolean;
	onCancel: () => void;
	onConfirm: (value: string) => void;
	onEdit: () => void;
	onValidate: (value: string) => string | undefined;
	readView: () => ReactElement;
	maxLength?: number;
};

const ERROR_MESSAGE_ID = 'field-name-error-dialog';

const InlineRenameField = ({
	defaultValue,
	isEditing,
	onCancel,
	onConfirm,
	onEdit,
	onValidate,
	readView,
	maxLength,
}: Props) => {
	const renameTextFieldRef = useRef<HTMLInputElement>(null);
	const { formatMessage } = useIntl();

	const handleOnBlur = useCallback(
		(event: React.FocusEvent<HTMLInputElement>, isInvalid: boolean) => {
			if (isInvalid) {
				onCancel();
			}
		},
		[onCancel],
	);

	const handleConfirm = useCallback(
		(value: string) => {
			onConfirm(value);
		},
		[onConfirm],
	);

	return (
		<InlineEdit
			startWithEditViewOpen={isEditing}
			readViewFitContainerWidth
			defaultValue={defaultValue}
			isEditing={isEditing}
			readView={readView}
			editView={({ errorMessage, ...fieldProps }) => (
				<InlineDialog
					isOpen={fieldProps.isInvalid}
					content={<Box id={ERROR_MESSAGE_ID}>{errorMessage}</Box>}
					placement="top-end"
				>
					<Textfield
						{...fieldProps}
						ref={renameTextFieldRef}
						autoFocus
						elemAfterInput={
							fieldProps.isInvalid && (
								<Box xcss={warningIconWrapperStyles}>
									<WarningIcon label="error" color={token('color.icon.warning')} />
								</Box>
							)
						}
						onBlur={(event) => handleOnBlur(event, fieldProps.isInvalid)}
						autoComplete="off"
						aria-errormessage={ERROR_MESSAGE_ID}
						isDisabled={!isEditing}
						appearance="subtle"
						aria-label={defaultValue}
						css={textFieldStyles}
						maxLength={maxLength}
					/>
				</InlineDialog>
			)}
			onEdit={onEdit}
			onCancel={onCancel}
			onConfirm={handleConfirm}
			validate={onValidate}
			editButtonLabel={defaultValue}
			editLabel={formatMessage(messages.editActionLabel)}
		/>
	);
};

const warningIconWrapperStyles = xcss({
	paddingInlineEnd: 'space.075',
	lineHeight: '100%',
	display: 'flex',
	alignItems: 'center',
});

const textFieldStyles = css({
	padding: 0,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > [data-ds--text-field--input]': {
		height: '100%',
	},
});

export default memo(InlineRenameField);
