import { styled } from '@compiled/react';
import { N30A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InviteTeammateButtonContainer = styled.div({
	marginLeft: token('space.negative.075', '-6px'),
	marginTop: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InviteTeammateIconContainer = styled.div({
	height: '32px',
	width: '32px',
	borderRadius: '50%',
	marginRight: token('space.075', '6px'),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: token('color.background.neutral', N30A),
});
