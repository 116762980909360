import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { N30A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const ICON_SIZE = 24;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledButton = styled(Button)({
	alignSelf: 'flex-start',
	marginLeft: token('space.negative.100', '-8px'),
	marginTop: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconWrapper = styled.div({
	height: `${ICON_SIZE}px`,
	width: `${ICON_SIZE}px`,
	borderRadius: token('border.radius', '4px'),
	marginRight: token('space.075', '6px'),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: token('color.background.neutral', N30A),
});
