import { styled } from '@compiled/react';
import { Link } from '@atlassian/react-resource-router';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AccessibleLink = styled(Link)({
	textDecoration: 'underline',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export default AccessibleLink;
