import React, { memo } from 'react';
import { N20A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

type Props = {
	className?: string;
	testId: string;
};

const Skeleton = ({ className, testId }: Props) => {
	const skeletonColor = token('color.skeleton', N20A);

	return (
		<svg
			viewBox="0 0 476 296"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={className}
			data-testid={testId}
		>
			<rect width="476" height="8" rx="4" fill={skeletonColor} />
			<rect y="16" width="320" height="8" rx="4" fill={skeletonColor} />
			<rect y="72" width="32" height="32" rx="16" fill={skeletonColor} />
			<rect x="40" y="80" width="170" height="16" rx="8" fill={skeletonColor} />
			<rect x="226" y="80" width="156" height="16" rx="8" fill={skeletonColor} />
			<rect x="398" y="80" width="78" height="16" rx="8" fill={skeletonColor} />
			<rect y="120" width="32" height="32" rx="16" fill={skeletonColor} />
			<rect x="40" y="128" width="170" height="16" rx="8" fill={skeletonColor} />
			<rect x="226" y="128" width="156" height="16" rx="8" fill={skeletonColor} />
			<rect x="398" y="128" width="78" height="16" rx="8" fill={skeletonColor} />
			<rect y="168" width="32" height="32" rx="16" fill={skeletonColor} />
			<rect x="40" y="176" width="170" height="16" rx="8" fill={skeletonColor} />
			<rect x="226" y="176" width="156" height="16" rx="8" fill={skeletonColor} />
			<rect x="398" y="176" width="78" height="16" rx="8" fill={skeletonColor} />
			<rect y="216" width="32" height="32" rx="16" fill={skeletonColor} />
			<rect x="40" y="224" width="170" height="16" rx="8" fill={skeletonColor} />
			<rect x="226" y="224" width="156" height="16" rx="8" fill={skeletonColor} />
			<rect x="398" y="224" width="78" height="16" rx="8" fill={skeletonColor} />
			<rect y="264" width="32" height="32" rx="16" fill={skeletonColor} />
			<rect x="40" y="272" width="170" height="16" rx="8" fill={skeletonColor} />
			<rect x="226" y="272" width="156" height="16" rx="8" fill={skeletonColor} />
			<rect x="398" y="272" width="78" height="16" rx="8" fill={skeletonColor} />
		</svg>
	);
};

export default memo<Props>(Skeleton);
