import { useEffect, useReducer } from 'react';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { RelatedProjectsDataType, HookResponseType } from './types.tsx';
import { transformData } from './utils.tsx';

const QUERY_NAME = 'collaborationGraph';
const MAX_RESULTS = 5;

const initialState = { data: undefined, loading: true, error: undefined } as const;

type Action =
	| { type: 'init' }
	| { type: 'success'; value: RelatedProjectsDataType }
	| { type: 'error'; value: Error };

const reducer = (state: HookResponseType, action: Action): HookResponseType => {
	switch (action.type) {
		case 'init':
			return initialState;
		case 'success':
			return { ...state, data: action.value, loading: false };
		case 'error':
			return { ...state, loading: false, error: action.value };
		default:
			return state;
	}
};

export const useRelatedProjects = (): HookResponseType => {
	const [{ data, loading, error }, dispatch] = useReducer(reducer, initialState);
	const { cloudId, atlassianAccountId } = useTenantContext();
	const project = useProject();

	const currentProjectId = String(project.id);

	useEffect(() => {
		dispatch({
			type: 'init',
		});

		fetchJson('/gateway/api/collaboration/v2/recommend/container', {
			method: 'POST',
			body: JSON.stringify({
				context: {
					userId: atlassianAccountId,
					tenantId: cloudId,
					containerId: currentProjectId,
					product: 'jira',
				},
				modelRequestParams: {
					experience: 'JiraContainerNearbyContainer',
					caller: 'jira',
				},
				requestingUserId: atlassianAccountId,
				expand: 'existing', // existing filters out projects that could not be hydrated
				maxNumberOfResults: MAX_RESULTS,
			}),
		})
			.then((graphResponse) =>
				dispatch({
					type: 'success',
					value: transformData(graphResponse, currentProjectId),
				}),
			)
			.catch((restError: Error) => {
				dispatch({ type: 'error', value: restError });

				fireErrorAnalytics({
					meta: {
						id: QUERY_NAME,
						packageName: 'jiraBusinessSummary',
						teamName: 'wanjel',
					},
					error: restError,
					sendToPrivacyUnsafeSplunk: true,
				});
			});
	}, [atlassianAccountId, cloudId, currentProjectId]);

	return {
		loading,
		error,
		data,
	};
};
