import React, { memo } from 'react';
import BusinessAppWrapper from '@atlassian/jira-business-app-wrapper/src/ui/app-wrapper/index.tsx';
import { ExperienceErrorBoundary } from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { WorkflowActionsProvider } from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { MAIN_EXPERIENCE } from './common/constants.tsx';
import { PrefetchedSummaryProvider } from './controllers/summary-context/index.tsx';
import SummaryView from './ui/summary-view/index.tsx';

const Summary = () => {
	const view = <SummaryView />;
	const [expConfig] = UNSAFE_noExposureExp('business_summary_page_inline_status');
	const isInlineStatusChangeEnabled = expConfig.get('isInlineStatusChangeEnabled', false);

	return (
		<ExperienceErrorBoundary experience={MAIN_EXPERIENCE} shouldStartExperience>
			<BusinessAppWrapper withOverflow>
				<ContextualAnalyticsData objectType="summary" sourceType={SCREEN} sourceName="summary">
					{isInlineStatusChangeEnabled ? (
						<WorkflowActionsProvider>
							<PrefetchedSummaryProvider>{view}</PrefetchedSummaryProvider>
						</WorkflowActionsProvider>
					) : (
						<PrefetchedSummaryProvider>{view}</PrefetchedSummaryProvider>
					)}
					<FireScreenAnalytics />
				</ContextualAnalyticsData>
			</BusinessAppWrapper>
		</ExperienceErrorBoundary>
	);
};

export default memo(Summary);
