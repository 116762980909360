import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	statusNameButtonLabel: {
		id: 'business-summary.summary-view.progress-by-status.legend-item.status-name-button-label',
		defaultMessage: 'Edit {statusName} status',
		description:
			'Label (used by screen readers) of the status name button that can be clicked to edit the status name',
	},
	statusNameTooltip: {
		id: 'business-summary.summary-view.progress-by-status.legend-item.status-name-tooltip',
		defaultMessage: 'Edit name',
		description: 'Tooltip of the status name button that can be clicked to edit the status name',
	},
	tableCountersLabel: {
		id: 'business-summary.summary-view.progress-by-status.legend-item.table-counters-label',
		defaultMessage:
			'{issuesCount, number} {issuesCount, plural, one {issue} other {issues}}, {percentage}% with the {status} status',
		description: 'Label for the items counter in the progress by status table.',
	},
});
