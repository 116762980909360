import React, { memo, type ComponentType } from 'react';
import '@atlaskit/css-reset';
import ProjectContextProvider from '@atlassian/jira-business-entity-project-provider/src/ui/project-context/index.tsx';
import FeaturesProvider from '@atlassian/jira-business-summary/src/controllers/features-context/index.tsx';
import SummaryView from '@atlassian/jira-business-summary/src/index.tsx';
import { useProject } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { AuthenticationCheck } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type JiraBusinessSummaryAppType = Record<any, any>;

const JiraBusinessSummaryApp: ComponentType<JiraBusinessSummaryAppType> = () => {
	const { data: project } = useProject();

	const hasAdministerProjectPermission = Boolean(project?.permissions.administerProject);
	const hasCreateItem = Boolean(project?.permissions.createIssues);

	return (
		<UFOSegment name="jira-business-summary-app">
			<AuthenticationCheck>
				<ProjectContextProvider>
					<FeaturesProvider
						hasCreateItem={hasCreateItem}
						hasInviteTeammateButton={hasAdministerProjectPermission}
						hasManageTypesButton={hasAdministerProjectPermission}
						issuesLinkUrl={project != null ? `/jira/core/projects/${project.key}/list` : ''}
					>
						<SummaryView />
					</FeaturesProvider>
				</ProjectContextProvider>
				<SubProductUpdater subProduct="core" />
			</AuthenticationCheck>
		</UFOSegment>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default memo<Record<any, any>>(JiraBusinessSummaryApp);
