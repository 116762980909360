import React, { memo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { LinkProps } from '@atlassian/react-resource-router';
import AccessibleLink from '../accessible-link/index.tsx';
import messages from './messages.tsx';

type Props = Pick<LinkProps, 'href' | 'onClick' | 'target'>;

const CreateItemLink = ({ href, onClick, target }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<AccessibleLink target={target} href={href} onClick={onClick}>
			{formatMessage(messages.createAnItem)}
		</AccessibleLink>
	);
};

export default memo(CreateItemLink);
