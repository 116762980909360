import React, { memo } from 'react';
import { JWMShareButton } from '@atlassian/jira-business-invite-people/src/ui/jwm-share-button/index.tsx';
import { integrationTypes } from '@atlassian/jira-share-button/src/constants.tsx';
import messages from './messages.tsx';

const ShareButton = () => (
	<JWMShareButton
		shareTitle={messages.shareTitle}
		integrationType={integrationTypes.SUMMARY}
		shareContentType="summary"
	/>
);

export default memo<{}>(ShareButton);
