import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import {
	ASSIGNEE_TYPE,
	CATEGORY_TYPE,
	COMPONENTS_TYPE,
	CREATED,
	ISSUE_TYPE,
	LABELS_TYPE,
	MULTI_SELECT_CF_TYPE,
	PEOPLE_CF_TYPE,
	PRIORITY_TYPE,
	PROJECT_TYPE,
	REPORTER_TYPE,
	SELECT_CF_TYPE,
	STATUS_TYPE,
	UPDATED,
	USER_CF_TYPE,
	DATE_CF_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	SPRINT_TYPE,
	FIX_VERSIONS_TYPE,
	PARENT_FIELD_TYPE,
	GOALS_CF_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';

export const POPUP_WIDTH = 360;
export const POPUP_PADDING = gridSize * 2.5;
export const SHOW_MORE_OPTIONS_BTN_SIZE = 32;
export const OPTIONS_AVAILABLE_WIDTH = POPUP_WIDTH - POPUP_PADDING * 2;
export const GAP_BETWEEN_OPTION = gridSize / 2;
export const OPTION_SPAN_PADDING = gridSize * 2;
export const OPTION_DEFAULT_FONT_SIZE = 14;
export const POPUP_OFFSET_TOP = 215;

export const OPTION_HEIGHT = 28;
export const OPTION_MAX_WIDTH = 150;

// date range is a virtual field type representing the range between start date and due date
export const DATE_RANGE_TYPE = 'dateRange';

export const BUSINESS_FIELD_TYPES = [
	ASSIGNEE_TYPE,
	CATEGORY_TYPE,
	COMPONENTS_TYPE,
	CREATED,
	DATE_RANGE_TYPE,
	ISSUE_TYPE,
	LABELS_TYPE,
	PRIORITY_TYPE,
	PROJECT_TYPE,
	REPORTER_TYPE,
	STATUS_TYPE,
	UPDATED,
	USER_CF_TYPE,
	SELECT_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	PEOPLE_CF_TYPE,
	DATE_CF_TYPE,
	GOALS_CF_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
	PARENT_FIELD_TYPE,
];

export const SOFTWARE_FIELD_TYPES = [SPRINT_TYPE, FIX_VERSIONS_TYPE];

// update this list as more fields become available
export const IMPLEMENTED_TYPES = new Set([...BUSINESS_FIELD_TYPES, ...SOFTWARE_FIELD_TYPES]);

export const EXTRA_CUSTOM_FIELD_TYPES = new Set([
	USER_CF_TYPE,
	SELECT_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	PEOPLE_CF_TYPE,
	DATE_CF_TYPE,
]);

export const SYSTEM_FIELD_JQL_TERM_TO_FIELD_TYPE: {
	[jqlTerm: string]: string;
} = {
	assignee: ASSIGNEE_TYPE,
	component: COMPONENTS_TYPE,
	created: CREATED,
	issuetype: ISSUE_TYPE,
	labels: LABELS_TYPE,
	priority: PRIORITY_TYPE,
	project: PROJECT_TYPE,
	reporter: REPORTER_TYPE,
	status: STATUS_TYPE,
	type: ISSUE_TYPE,
	updated: UPDATED,
};

export const GAP_SIZE = gridSize * 3.5;
export const SAVED_FILTERS_PER_PAGE = 50;

export const USERS_DATA_KEY = 'users';
export const RECENTLY_USED_USERS_DATA_KEY = 'recentlyUsedUsers';
