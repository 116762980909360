import { useCallback } from 'react';
import { GraphQLErrors } from '@atlassian/jira-business-graphql-errors/src/index.tsx';
import type { IssueLayoutConfiguration } from '@atlassian/jira-business-summary-services/src/services/types.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/index.tsx';
import { ISSUE_LAYOUT_CONFIGURATION_QUERY } from './gql.tsx';
import { transformIssueLayoutConfiguration } from './utils.tsx';

const QUERY_NAME = 'IssueLayoutConfigurationData';

type Fetch = () => Promise<IssueLayoutConfiguration>;

const useFetchIssueLayoutConfiguration = (projectId: number, extraDefinerId: string) => {
	const fetch: Fetch = useCallback(
		() =>
			performPostRequest(`/rest/gira/1/?operation=${QUERY_NAME}`, {
				body: JSON.stringify({
					query: ISSUE_LAYOUT_CONFIGURATION_QUERY,
					variables: {
						projectId,
						extraDefinerId,
					},
				}),
			})
				.then((response) => {
					if (response.errors != null) {
						throw new GraphQLErrors(response.errors);
					}

					const transformed = transformIssueLayoutConfiguration(response.data);

					if (
						transformed.owners.avatarId == null ||
						transformed.owners.description == null ||
						transformed.id == null ||
						transformed.name == null
					) {
						throw new Error('query result is not valid');
					}

					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					return transformed as IssueLayoutConfiguration;
				})
				.catch((error) => {
					fireErrorAnalytics({
						meta: {
							id: QUERY_NAME,
							packageName: 'jiraBusinessSummary',
							teamName: 'wanjel',
						},
						error,
						sendToPrivacyUnsafeSplunk: true,
					});

					throw error;
				}),
		[extraDefinerId, projectId],
	);

	return fetch;
};

export default useFetchIssueLayoutConfiguration;
