import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'business-summary.summary-view.types-of-work.title',
		defaultMessage: 'Types of work',
		description: 'Title of the types of work summary section.',
	},
	description: {
		id: 'business-summary.summary-view.types-of-work.description',
		defaultMessage: 'Get a breakdown of items by their types. {linkStart}View all items{linkEnd}',
		description: 'Description of the types of work summary section.',
	},
	descriptionWhenEmpty: {
		id: 'business-summary.summary-view.types-of-work.description-when-empty',
		defaultMessage: "You'll need to create a few items for your project to get started.",
		description: 'Description of the types of work summary section when the project has no issues.',
	},
	issueTypeColumnHeader: {
		id: 'business-summary.summary-view.types-of-work.issue-type-column-header',
		defaultMessage: 'Type',
		description: 'Header of the issue type column.',
	},
	issueDistributionColumnHeader: {
		id: 'business-summary.summary-view.types-of-work.issue-distribution-column-header',
		defaultMessage: 'Distribution',
		description: 'Header of the issue distribution column.',
	},
	countColumnHeader: {
		id: 'business-summary.summary-view.types-of-work.count-column-header',
		defaultMessage: 'Count',
		description: 'Header of the count column.',
	},
	viewAllItemsLabel: {
		id: 'business-summary.summary-view.types-of-work.view-all-items-label',
		defaultMessage: 'View all issues with different types of work',
		description:
			'Description of the link that leads to a page that shows issues with different types of work.',
	},
});
