import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorFlagTitleRenameIssueType: {
		id: 'business-summary.controllers.rename-issue-types.error-flag-title-rename-issue-type',
		defaultMessage: 'Unable to rename',
		description: 'Title of the error flag shown to a user if rename issue type is unsuccessful',
	},
	issueTypeNameNetworkError: {
		id: 'business-summary.controllers.rename-issue-types.issue-type-name-network-error',
		defaultMessage: 'Unable to rename. Try again.',
		description:
			'Error message to be shown to user when user tries to rename issue type and there is a network issue',
	},
	issueTypeNameAlreadyInUse: {
		id: 'business-summary.controllers.rename-issue-types.issue-type-name-already-in-use',
		defaultMessage: 'Issue type already exists. Try a different name.',
		description:
			'Error message to be shown to user when user tries to rename issue type into name that is already in use',
	},
});
