import React, { useCallback, useState, useMemo } from 'react';
import EditIcon from '@atlaskit/icon/core/edit';
import Image from '@atlaskit/image';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { OPEN_ISSUES_JQL } from '@atlassian/jira-business-summary-services/src/services/constants.tsx';
import type { IssueTypeType } from '@atlassian/jira-business-summary-services/src/services/types.tsx';
import { showFlag } from '@atlassian/jira-flags/src/services';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { ISSUE_TYPE_MAX_LENGTH } from '../../../../common/constants.tsx';
import InlineRenameField from '../../../../common/ui/inline-rename-field/index.tsx';
import PercentageBarRow from '../../../../common/ui/percentage-bar-table/percentage-bar-row/index.tsx';
import { getCleanIssueTypesName, useSectionAnalytics } from '../../../../common/utils.tsx';
import { useSummaryFeatures } from '../../../../controllers/features-context/index.tsx';
import { useRenameIssueType } from '../../../../controllers/rename-issue-types/index.tsx';
import { ICON_SIZE } from '../manage-types-button/styled.tsx';
import messages from './messages.tsx';
import { getInvalidIssueTypeNameContent } from './utils.tsx';

const IssueTypeRow = ({
	count,
	totalCount,
	issueType,
	position,
	issueTypeNames,
	refetchIssueTypes,
}: {
	count: number;
	totalCount: number;
	issueType: IssueTypeType;
	position: number;
	issueTypeNames?: string[];
	refetchIssueTypes: () => void;
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const { linksTarget, issuesLinkUrl } = useSummaryFeatures();
	const { fireSectionAnalytics } = useSectionAnalytics(position, 'typesOfWork');
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [optimisticallyUpdatedName, setOptimisticallyUpdatedName] = useState('');
	const { formatMessage } = useIntl();

	const issueTypeFilteredListViewUrl = useCallback(
		(issueTypeName: string): string =>
			`${issuesLinkUrl}?filter=${encodeURIComponent(
				`type = '${issueTypeName.split("'").join("\\'")}' AND ${OPEN_ISSUES_JQL}`,
			)}`,
		[issuesLinkUrl],
	);

	const renameIssueType = useRenameIssueType(issueType);

	const upperCaseIssueTypeNames = useMemo(
		() => issueTypeNames?.map((name) => name.toUpperCase()),
		[issueTypeNames],
	);

	const validateIssueTypeName = useCallback(
		(newName: string) =>
			getInvalidIssueTypeNameContent({
				newName,
				oldName: issueType.name,
				issueTypeNameList: upperCaseIssueTypeNames,
				formatMessage,
			})?.inline,
		[issueType.name, upperCaseIssueTypeNames, formatMessage],
	);

	const saveIssueTypeName = useCallback(
		async (name: string) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'edit submitted', 'inlineEditIssueTypes');

			setIsEditing(false);

			if (!name) {
				return undefined;
			}

			if (name === issueType.name) {
				return undefined;
			}

			const cleanedName = getCleanIssueTypesName(name);

			const invalidIssueTypeFlag = getInvalidIssueTypeNameContent({
				newName: name,
				oldName: issueType.name,
				issueTypeNameList: upperCaseIssueTypeNames,
				formatMessage,
			})?.flag;

			if (invalidIssueTypeFlag) {
				showFlag(invalidIssueTypeFlag);
				return;
			}

			setOptimisticallyUpdatedName(cleanedName);
			await renameIssueType(cleanedName, refetchIssueTypes);
			setOptimisticallyUpdatedName('');
		},
		[
			issueType.name,
			upperCaseIssueTypeNames,
			formatMessage,
			renameIssueType,
			refetchIssueTypes,
			createAnalyticsEvent,
		],
	);

	const handleEditIssueType = useCallback(() => {
		setIsEditing(true);
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'inlineEditIssueTypes');
	}, [createAnalyticsEvent]);

	return (
		<PercentageBarRow
			iconBefore={
				<Inline xcss={IssueTypeIconStyles}>
					<Image
						src={issueType.iconUrl}
						alt=""
						height={`${ICON_SIZE}px`}
						width={`${ICON_SIZE}px`}
					/>
				</Inline>
			}
			itemCount={count}
			totalCount={totalCount}
			linkUrl={issueTypeFilteredListViewUrl(issueType.name)}
			onRowLinkClick={() =>
				fireSectionAnalytics('viewWithFilter', {
					count,
				})
			}
			linkTarget={linksTarget}
			name={!isEditing ? optimisticallyUpdatedName || issueType.name : undefined}
			actions={
				<Box paddingBlockEnd="space.100">
					<InlineRenameField
						defaultValue={issueType.name}
						isEditing={isEditing}
						readView={() => (
							<Box
								xcss={iconButtonStyles}
								testId="business-summary.ui.summary-view.types-of-work.issue-type-row.edit-issue-type-button"
							>
								<Tooltip content={formatMessage(messages.editButtonTooltip)} position="bottom">
									<EditIcon label="" color={token('color.icon')} />
								</Tooltip>
							</Box>
						)}
						onCancel={() => setIsEditing(false)}
						onConfirm={(updatedName: string) => saveIssueTypeName(updatedName)}
						onEdit={handleEditIssueType}
						onValidate={validateIssueTypeName}
						maxLength={ISSUE_TYPE_MAX_LENGTH}
					/>
				</Box>
			}
		/>
	);
};

const iconButtonStyles = xcss({
	cursor: 'pointer',
});

const IssueTypeIconStyles = xcss({
	borderRadius: 'border.radius.050',
	flex: 'none',
});

export default IssueTypeRow;
