import type {
	Status,
	StatusUpdate,
	Transition,
	Workflow,
	WorkflowUpdate,
	WorkflowV2Response,
} from './types.tsx';

export const mapStatusesToStatusUpdateObjects = (statuses: Status[]): StatusUpdate[] =>
	statuses.map((s) => ({
		id: s.id,
		name: s.name,
		description: s.description,
		statusReference: s.statusReference,
		statusCategory: s.statusCategory,
	}));

export const mapWorkflowToWorkflowUpdateObject = (workflow: Workflow): WorkflowUpdate => ({
	id: workflow.id,
	version: workflow.version,
	description: workflow.description,
	statuses: workflow.statuses.map(({ deprecated, ...status }) => status),
	transitions: workflow.transitions,
	startPointLayout: workflow.startPointLayout,
	statusMappings: workflow.statusMappings || [],
});

export const removeWorkflowAssociatedWithSubtaskOnly = (
	workflowV2Response: WorkflowV2Response,
	subtaskIssueTypeId?: string,
): WorkflowV2Response => {
	if (subtaskIssueTypeId == null) {
		return workflowV2Response;
	}

	const filteredWorkflows = workflowV2Response.workflows.filter(
		(wf) =>
			// It checks if all workflow associations have only one issue type, and if that issue type is 'subtask'.
			// If this condition is met, it filters out the workflow.
			!wf.associatedWith.every(
				(association) =>
					association.issueTypes.length === 1 && association.issueTypes[0] === subtaskIssueTypeId,
			),
	);

	// if no workflow specific for subtask, return original response
	if (filteredWorkflows.length === workflowV2Response.workflows.length) {
		return workflowV2Response;
	}

	return {
		...workflowV2Response,
		workflows: filteredWorkflows,
	};
};

export const getUniqueTransitionId = (transitions: Array<Transition>) => {
	const largestId = Math.max(...transitions.map((t) => parseInt(t.id, 10)));
	return String(largestId + 1);
};
