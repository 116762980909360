import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	today: {
		id: 'business-summary.summary-view.activity-feed.today',
		defaultMessage: 'Today',
		description: 'Title of grouping for project activities that occured today.',
	},
	yesterday: {
		id: 'business-summary.summary-view.activity-feed.yesterday',
		defaultMessage: 'Yesterday',
		description: 'Title of grouping for project activities that occured yesterday.',
	},
	title: {
		id: 'business-summary.summary-view.activity-feed.title',
		defaultMessage: 'Recent activity',
		description: 'Title of the activity feed summary section.',
	},
	description: {
		id: 'business-summary.summary-view.activity-feed.description',
		defaultMessage: "Stay up to date with what's happening across the project. ",
		description: 'Description of the activity feed summary section.',
	},
	descriptionWhenEmpty: {
		id: 'business-summary.summary-view.activity-feed.description-when-empty',
		defaultMessage:
			'Create a few items and invite teammates to your project to see your project activity.',
		description: 'Description of the activity feed summary section when there is no data to show.',
	},
});
