import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'business-summary.summary-view.breakdown-by-priority.title',
		defaultMessage: 'Priority breakdown',
		description: 'Title of the breakdown by priority summary section.',
	},
	description: {
		id: 'business-summary.summary-view.breakdown-by-priority.description',
		defaultMessage:
			"Get a holistic view of how work is being prioritized. {linkStart}See what your team's been focusing on{linkEnd}",
		description: 'Description of the breakdown by priority summary section.',
	},
	descriptionWhenEmpty: {
		id: 'business-summary.summary-view.breakdown-by-priority.description-when-empty',
		defaultMessage: "You'll need to create a few items before you can start prioritizing work.",
		description:
			'Description of the breakdown by priority summary section when the project is empty.',
	},
	noPriorityLabel: {
		id: 'business-summary.summary-view.breakdown-by-priority.no-priority-label',
		defaultMessage: 'None',
		description: 'The label for the no priority bar on the breakdown of priority summary section.',
	},
	morePrioritiesLabel: {
		id: 'business-summary.summary-view.breakdown-by-priority.more-priorities-label',
		defaultMessage: 'More',
		description: 'The label for the more bar on the breakdown of priority summary section.',
	},
});
