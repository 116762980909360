import React, { memo } from 'react';
import { N20A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

type Props = {
	className?: string;
	testId: string;
};

const Skeleton = ({ className, testId }: Props) => {
	const skeletonColor = token('color.skeleton', N20A);

	return (
		<svg
			viewBox="0 0 476 312"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={className}
			data-testid={testId}
		>
			<rect width="476" height="8" rx="4" fill={skeletonColor} />
			<rect y="16" width="320" height="8" rx="4" fill={skeletonColor} />
			<rect y="56" width="48" height="48" rx="4" fill={skeletonColor} />
			<rect x="64" y="68" width="160" height="8" rx="4" fill={skeletonColor} />
			<rect x="64" y="84" width="80" height="8" rx="4" fill={skeletonColor} />
			<rect x="252" y="56" width="48" height="48" rx="4" fill={skeletonColor} />
			<rect x="316" y="68" width="160" height="8" rx="4" fill={skeletonColor} />
			<rect x="316" y="84" width="80" height="8" rx="4" fill={skeletonColor} />
			<rect y="136" width="48" height="48" rx="4" fill={skeletonColor} />
			<rect x="64" y="148" width="160" height="8" rx="4" fill={skeletonColor} />
			<rect x="64" y="164" width="80" height="8" rx="4" fill={skeletonColor} />
			<rect x="252" y="136" width="48" height="48" rx="4" fill={skeletonColor} />
			<rect x="316" y="148" width="160" height="8" rx="4" fill={skeletonColor} />
			<rect x="316" y="164" width="80" height="8" rx="4" fill={skeletonColor} />
			<rect y="216" width="48" height="48" rx="4" fill={skeletonColor} />
			<rect x="64" y="228" width="160" height="8" rx="4" fill={skeletonColor} />
			<rect x="64" y="244" width="80" height="8" rx="4" fill={skeletonColor} />
			<rect x="252" y="216" width="48" height="48" rx="4" fill={skeletonColor} />
			<rect x="316" y="228" width="160" height="8" rx="4" fill={skeletonColor} />
			<rect x="316" y="244" width="80" height="8" rx="4" fill={skeletonColor} />
			<rect y="296" width="180" height="16" rx="8" fill={skeletonColor} />
		</svg>
	);
};

export default memo<Props>(Skeleton);
