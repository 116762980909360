import {
	N40,
	N300,
	N600,
	B200,
	B300,
	G200,
	G300,
	Y200,
	Y300,
	T200,
	T300,
	P200,
	P300,
	R200,
	R300,
} from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { JiraIssueStatusAri } from '@atlassian/ari/jira/issue-status';
import type { IssuesByStatusDataType } from '@atlassian/jira-business-summary-services/src/services/summary-data/types.tsx';
import { fontFamily } from '@atlassian/jira-common-styles/src/main.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';

const NEUTRAL = [N40, token('color.text.subtle', N300)];
const BLUE = [B200, B300];
const GREEN = [G200, G300];
const YELLOW = [Y200, Y300];
const TEAL = [T200, T300];
const PURPLE = [P200, P300];
const RED = [R200, R300];

const CATEGORY_COLORS = {
	new: NEUTRAL,
	indeterminate: BLUE,
	done: GREEN,
} as const;

const OTHER_COLORS = [YELLOW, TEAL, PURPLE, RED];

const StatusCategoryOrder = {
	new: 0,
	indeterminate: 1,
	done: 2,
} as const;

export type ChartEntry = {
	categoryKey: string;
	value: number;
	name: string;
	id: number | null;
	link: string;
	itemStyle: {
		color: string;
	};
	emphasis: {
		label: {
			rich: {
				percent: {
					color: string;
				};
			};
		};
	};
};

export const buildChartData = (
	data: IssuesByStatusDataType | null | undefined,
	issuesLinkUrl: string,
) => {
	if (data == null) {
		return null;
	}

	const categoryColors = {
		...CATEGORY_COLORS,
	};
	let otherColors: string[][];

	let totalCount = 0;
	let largestDoneStatusCount = 0;
	let largestDoneStatusIndex = 0;
	const entries = [...data]
		.sort((a, b) => {
			const comparison =
				StatusCategoryOrder[a.status.category.key] - StatusCategoryOrder[b.status.category.key];

			if (comparison === 0) {
				return a.status.name.localeCompare(b.status.name);
			}

			return comparison;
		})
		.map<ChartEntry>((entry, index) => {
			const categoryKey = entry.status.category.key;
			totalCount += entry.count;
			const isDone = categoryKey === 'done';
			if (isDone && entry.count > largestDoneStatusCount) {
				largestDoneStatusCount = entry.count;
				largestDoneStatusIndex = index;
			}

			if (!otherColors?.length) {
				otherColors = [...OTHER_COLORS];
			}

			const [color, textColor] = categoryColors[categoryKey] || otherColors.pop();
			delete categoryColors[categoryKey];

			const escapedStatus = entry.status.name.split("'").join("\\'");

			const status = entry.status.jqlTerm ?? escapedStatus;
			const filter = isDone
				? `status = '${status}' AND statusCategoryChangedDate >= -2w`
				: `status = '${status}'`;

			const link = `${issuesLinkUrl}?filter=${encodeURIComponent(
				filter,
			)}&hideDone=${isDone ? 'false' : 'true'}`;

			const [expConfig] = UNSAFE_noExposureExp('business_summary_page_inline_status');
			const isInlineStatusChangeEnabled = expConfig.get('isInlineStatusChangeEnabled', false);

			const statusId = isInlineStatusChangeEnabled
				? JiraIssueStatusAri.parse(entry.status.statusId).issuestatusId
				: '';

			return {
				categoryKey,
				value: entry.count,
				name: entry.status.name,
				id: isInlineStatusChangeEnabled ? Number(statusId) : null,
				link,
				itemStyle: { color },
				emphasis: {
					label: {
						rich: {
							percent: {
								color: textColor,
							},
						},
					},
				},
			};
		});

	const option = {
		series: [
			{
				animation: false,
				type: 'pie',
				radius: ['50%', '95%'],
				clockwise: false,
				label: {
					show: false,
					position: 'center',
				},
				labelLine: {
					show: false,
				},
				emphasis: {
					disabled: true,
					scale: false,
					label: {
						show: true,
						color: token('color.text.subtle', N600),
						fontSize: 14,
						fontFamily,
						fontWeight: 500,
						overflow: 'truncate',
						width: 100,
						formatter: ({ name, value }: { name: string; value: number }) =>
							`{percent|${Math.round((value / totalCount) * 100)}%}\n${name}`,
						rich: {
							percent: {
								fontSize: 36,
							},
						},
					},
					itemStyle: {
						color: 'inherit',
						opacity: 0.9,
					},
				},
				data: entries,
			},
		],
	};

	return { option, entries, totalCount, initialHighlight: largestDoneStatusIndex };
};
