import React, { memo } from 'react';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';

type Props = {
	testId: string;
};

const SummaryHeaderSkeleton = ({ testId }: Props) => (
	<svg
		height="32px"
		width="400px"
		viewBox="0 0 400 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		data-testid={testId}
	>
		<rect x="0" y="0" width="400px" height="32px" rx="4" fill={Tokens.COLOR_SKELETON} />
	</svg>
);

export default memo<Props>(SummaryHeaderSkeleton);
