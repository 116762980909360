import React, { memo } from 'react';

type Props = {
	className?: string;
};

const EmptyIllustration = ({ className }: Props) => (
	<svg
		height="140"
		viewBox="0 0 270 197"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		className={className}
	>
		<g clipPath="url(#related_proj_0_clip)">
			<path
				d="M38.1121 159.386L36.6245 161.141L73.9342 192.782L75.4219 191.027L38.1121 159.386Z"
				fill="#B2D4FF"
			/>
			<path
				d="M22.9727 98.5313L22.6646 100.811L73.6011 107.697L73.9092 105.418L22.9727 98.5313Z"
				fill="#B2D4FF"
			/>
			<path
				d="M69.1668 57.4796L67.4548 59.0156L94.3284 88.9667L96.0403 87.4307L69.1668 57.4796Z"
				fill="#B2D4FF"
			/>
			<path
				d="M174.162 69.7903L173.337 71.9375L222.285 90.7267L223.109 88.5795L174.162 69.7903Z"
				fill="#B2D4FF"
			/>
			<path
				d="M160.704 120.078L159.843 122.211L229.827 150.486L230.688 148.354L160.704 120.078Z"
				fill="#B2D4FF"
			/>
			<path
				d="M223.721 88.5916L221.444 88.8906L229.276 148.538L231.553 148.239L223.721 88.5916Z"
				fill="#B2D4FF"
			/>
			<path
				d="M67.4944 57.4183L22.0071 98.8086L23.5348 100.488L69.0221 59.0973L67.4944 57.4183Z"
				fill="#B2D4FF"
			/>
			<path
				d="M23.9733 99.3967L21.7368 99.9336L36.3085 160.629L38.5449 160.092L23.9733 99.3967Z"
				fill="#B2D4FF"
			/>
			<path
				d="M229.603 148.698L198.452 179.203L200.041 180.825L231.192 150.32L229.603 148.698Z"
				fill="#B2D4FF"
			/>
			<path
				d="M264.105 130.299L229.537 148.141L230.578 150.158L265.146 132.316L264.105 130.299Z"
				fill="#B2D4FF"
			/>
			<path
				d="M129.777 123.241L37.2278 158.953L38.045 161.071L130.594 125.359L129.777 123.241Z"
				fill="#B2D4FF"
			/>
			<path
				d="M222.268 88.5009L129.818 123.066L130.613 125.193L223.063 90.6272L222.268 88.5009Z"
				fill="#B2D4FF"
			/>
			<path
				d="M130.3 195.9C169.899 195.9 202 163.799 202 124.2C202 84.6012 169.899 52.5 130.3 52.5C90.701 52.5 58.5999 84.6012 58.5999 124.2C58.5999 163.799 90.701 195.9 130.3 195.9Z"
				fill="url(#related_proj_0_linear)"
			/>
			<g opacity="0.5">
				<path
					opacity="0.5"
					d="M129 79.8008H99.7999C99.4999 80.6008 99.1 81.4008 98.7 82.3008H129V79.8008Z"
					fill="#B2D4FF"
				/>
				<path
					opacity="0.5"
					d="M73.9999 79.8008C73.3999 80.6008 72.6999 81.4008 72.0999 82.3008H96.2999C96.6058 81.442 96.9734 80.6065 97.3999 79.8008H73.9999Z"
					fill="#B2D4FF"
				/>
				<path
					opacity="0.5"
					d="M188.4 82.3008C187.823 81.426 187.188 80.5908 186.5 79.8008H164.7C165 80.6008 165.4 81.4008 165.7 82.3008H188.4Z"
					fill="#B2D4FF"
				/>
				<path
					opacity="0.5"
					d="M131.5 82.3008H163.3C162.9 81.4008 162.6 80.6008 162.2 79.8008H131.5V82.3008Z"
					fill="#B2D4FF"
				/>
				<path
					opacity="0.5"
					d="M129 166.1H97.2C97.6 166.9 97.8999 167.8 98.2999 168.6H129V166.1Z"
					fill="#B2D4FF"
				/>
				<path
					opacity="0.5"
					d="M72.0999 166.1L73.9999 168.6H95.7999L94.7999 166.1H72.0999Z"
					fill="#B2D4FF"
				/>
				<path
					opacity="0.5"
					d="M186.5 168.6C187.2 167.8 187.8 166.9 188.4 166.1H164.2C163.875 166.951 163.508 167.785 163.1 168.6H186.5Z"
					fill="#B2D4FF"
				/>
				<path
					opacity="0.5"
					d="M131.5 168.6H160.7C161.108 167.785 161.475 166.951 161.8 166.1H131.5V168.6Z"
					fill="#B2D4FF"
				/>
				<path
					opacity="0.5"
					d="M98.6998 82.3C99.0998 81.4 99.4998 80.6 99.7998 79.8C107 64.9 117.4 55 129 53.8V52.5H131.5V53.7H132.1C142.7 53.9 152.5 61.5 159.8 74.8C160.6 76.4 161.5 78.1 162.2 79.8C162.9 81.5 162.9 81.4 163.3 82.3C168.1 94 170.6 108.1 170.5 122.9H172.7C172.8 108.2 170.4 94.1 165.7 82.3C165.4 81.4 165 80.6 164.7 79.8C164.4 79 162.8 75.7 161.8 73.7C156.2 63.5 149.2 56.6 141.4 53.4C138.323 52.8926 135.216 52.5919 132.1 52.5C129 52.5 125.9 52.6 122.9 52.9C112.7 56.5 103.7 66.2 97.3998 79.8C96.9733 80.6057 96.6057 81.4412 96.2998 82.3C91.2998 93.8 88.1998 107.8 87.7998 122.9H90.0998C90.3473 108.942 93.2666 95.1599 98.6998 82.3Z"
					fill="#B2D4FF"
				/>
				<path
					opacity="0.5"
					d="M164.2 166.1C169.578 153.192 172.463 139.382 172.7 125.4H170.4C170.163 139.392 167.243 153.209 161.8 166.1C161.475 166.952 161.108 167.786 160.7 168.6C153.5 183.5 143.1 193.3 131.5 194.5V195.6H129V194.7H128.4C117.8 194.4 108 186.9 100.7 173.5L98.2999 168.6C97.8999 167.8 97.5999 166.9 97.1999 166.1C92.3999 154.3 89.8999 140.2 90.0999 125.4H87.7999C87.6999 140.2 90.0999 154.2 94.7999 166.1L95.7999 168.6C96.6999 170.7 97.6999 172.7 98.6999 174.6C104.3 184.8 111.3 191.8 119.1 195C122.2 195.4 125.2 195.7 128.4 195.8C131.506 195.917 134.616 195.783 137.7 195.4C147.8 191.9 156.8 182.1 163.1 168.6C163.508 167.786 163.875 166.952 164.2 166.1Z"
					fill="#B2D4FF"
				/>
				<path
					opacity="0.5"
					d="M131.5 79.8V53.7V52.5H129V53.8V79.8V82.3V122.9H131.5V82.3V79.8Z"
					fill="#B2D4FF"
				/>
				<path
					opacity="0.5"
					d="M131.5 166.1V125.4H129V166.1V168.6V194.7V195.6H131.5V194.5V168.6V166.1Z"
					fill="#B2D4FF"
				/>
				<path
					opacity="0.5"
					d="M129 125.4H131.5H170.4H172.7H201.9V122.9H172.7H170.5H131.5H129H90.0999H87.7999H58.8999V125.4H87.7999H90.0999H129Z"
					fill="#B2D4FF"
				/>
			</g>
			<g opacity="0.4">
				<path
					opacity="0.4"
					d="M78.1999 155C77.959 155.028 77.7148 154.997 77.4884 154.91C77.262 154.822 77.06 154.682 76.8999 154.5C76.7084 154.331 76.555 154.123 76.4499 153.89C76.3449 153.657 76.2905 153.405 76.2905 153.15C76.2905 152.894 76.3449 152.642 76.4499 152.409C76.555 152.176 76.7084 151.968 76.8999 151.8L128.9 99.7995C129.252 99.4691 129.717 99.2852 130.2 99.2852C130.683 99.2852 131.148 99.4691 131.5 99.7995C131.691 99.9684 131.845 100.176 131.95 100.409C132.055 100.642 132.109 100.894 132.109 101.15C132.109 101.405 132.055 101.657 131.95 101.89C131.845 102.123 131.691 102.331 131.5 102.5L79.4999 154.5C79.3398 154.682 79.1379 154.822 78.9115 154.91C78.6851 154.997 78.4409 155.028 78.1999 155Z"
					fill="url(#related_proj_1_linear)"
				/>
			</g>
			<path
				d="M200.9 55.4995C177.8 46.0995 154.6 64.8995 131.5 55.4995V3.29948C154.6 12.6995 177.8 -6.10052 200.9 3.29948V55.4995Z"
				fill="url(#related_proj_2_linear)"
			/>
			<path
				d="M130.2 102.9C129.739 102.901 129.296 102.725 128.961 102.409C128.626 102.092 128.425 101.66 128.4 101.2V3.3C128.4 2.82261 128.59 2.36477 128.927 2.02721C129.265 1.68964 129.723 1.5 130.2 1.5C130.677 1.5 131.135 1.68964 131.473 2.02721C131.81 2.36477 132 2.82261 132 3.3V101.2C131.974 101.66 131.773 102.092 131.439 102.409C131.104 102.725 130.66 102.901 130.2 102.9Z"
				fill="#0052CC"
			/>
			<path
				d="M166.8 15.4994L170.9 23.8994L180.1 25.0994C180.259 25.1278 180.408 25.1987 180.53 25.3045C180.653 25.4104 180.745 25.5473 180.796 25.7008C180.847 25.8544 180.856 26.0189 180.821 26.177C180.787 26.3351 180.71 26.4811 180.6 26.5994L174 33.2994L175.5 42.5994C175.546 42.756 175.544 42.923 175.492 43.0781C175.441 43.2331 175.344 43.3687 175.213 43.4667C175.082 43.5647 174.925 43.6203 174.762 43.6262C174.598 43.632 174.437 43.5878 174.3 43.4994L166 39.2994L157.8 43.7994C157.655 43.8723 157.494 43.9048 157.332 43.8936C157.171 43.8824 157.016 43.8279 156.882 43.7357C156.749 43.6436 156.644 43.5173 156.576 43.3701C156.509 43.2229 156.483 43.0603 156.5 42.8994L158.1 33.5994L151.4 27.0994C151.29 26.9811 151.213 26.8351 151.179 26.677C151.144 26.5189 151.153 26.3544 151.204 26.2008C151.255 26.0473 151.347 25.9104 151.469 25.8045C151.592 25.6987 151.741 25.6278 151.9 25.5994L161.1 24.0994L165.2 15.4994C165.276 15.3525 165.39 15.2293 165.531 15.1433C165.673 15.0572 165.835 15.0117 166 15.0117C166.165 15.0117 166.327 15.0572 166.468 15.1433C166.609 15.2293 166.724 15.3525 166.8 15.4994Z"
				fill="#FFF0B2"
			/>
			<path
				d="M68.3 68.7996C74.1542 68.7996 78.9 64.0538 78.9 58.1996C78.9 52.3454 74.1542 47.5996 68.3 47.5996C62.4457 47.5996 57.7 52.3454 57.7 58.1996C57.7 64.0538 62.4457 68.7996 68.3 68.7996Z"
				fill="#4C9AFF"
			/>
			<path
				d="M199.2 190.6C205.054 190.6 209.8 185.855 209.8 180C209.8 174.146 205.054 169.4 199.2 169.4C193.346 169.4 188.6 174.146 188.6 180C188.6 185.855 193.346 190.6 199.2 190.6Z"
				fill="url(#related_proj_3_linear)"
			/>
			<path
				d="M37.3 170.899C43.1542 170.899 47.9 166.153 47.9 160.299C47.9 154.445 43.1542 149.699 37.3 149.699C31.4457 149.699 26.7 154.445 26.7 160.299C26.7 166.153 31.4457 170.899 37.3 170.899Z"
				fill="url(#related_proj_4_linear)"
			/>
			<path
				d="M230.4 154.3C233.106 154.3 235.3 152.106 235.3 149.4C235.3 146.694 233.106 144.5 230.4 144.5C227.694 144.5 225.5 146.694 225.5 149.4C225.5 152.106 227.694 154.3 230.4 154.3Z"
				fill="#FFC400"
			/>
			<path
				d="M222.7 94.5988C225.406 94.5988 227.6 92.405 227.6 89.6988C227.6 86.9926 225.406 84.7988 222.7 84.7988C219.994 84.7988 217.8 86.9926 217.8 89.6988C217.8 92.405 219.994 94.5988 222.7 94.5988Z"
				fill="#505F79"
			/>
			<path
				d="M264.4 136.4C267.106 136.4 269.3 134.206 269.3 131.5C269.3 128.793 267.106 126.6 264.4 126.6C261.694 126.6 259.5 128.793 259.5 131.5C259.5 134.206 261.694 136.4 264.4 136.4Z"
				fill="#4C9AFF"
			/>
			<path
				d="M6.04613 73.1738L4.15063 74.4766L21.8622 100.247L23.7576 98.9443L6.04613 73.1738Z"
				fill="#B2D4FF"
			/>
			<path
				d="M5.2999 78.4992C8.0061 78.4992 10.1999 76.3054 10.1999 73.5992C10.1999 70.893 8.0061 68.6992 5.2999 68.6992C2.59371 68.6992 0.399902 70.893 0.399902 73.5992C0.399902 76.3054 2.59371 78.4992 5.2999 78.4992Z"
				fill="#B2D4FF"
			/>
			<path
				d="M22.7999 104.499C25.5061 104.499 27.6999 102.305 27.6999 99.5992C27.6999 96.893 25.5061 94.6992 22.7999 94.6992C20.0937 94.6992 17.8999 96.893 17.8999 99.5992C17.8999 102.305 20.0937 104.499 22.7999 104.499Z"
				fill="#FFC400"
			/>
			<path
				d="M74.5999 196.9C77.3061 196.9 79.4999 194.706 79.4999 192C79.4999 189.293 77.3061 187.1 74.5999 187.1C71.8937 187.1 69.7 189.293 69.7 192C69.7 194.706 71.8937 196.9 74.5999 196.9Z"
				fill="#505F79"
			/>
		</g>
		<defs>
			<linearGradient
				id="related_proj_0_linear"
				x1="79.5799"
				y1="174.85"
				x2="180.94"
				y2="73.5"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#0065FF" />
				<stop offset="0.67" stopColor="#4C9AFF" />
				<stop offset="0.99" stopColor="#B2D4FF" />
			</linearGradient>
			<linearGradient
				id="related_proj_1_linear"
				x1="76.3199"
				y1="127.16"
				x2="132.09"
				y2="127.16"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#0052CC" stopOpacity="0" />
				<stop offset="1" stopColor="#0052CC" />
			</linearGradient>
			<linearGradient
				id="related_proj_2_linear"
				x1="135.77"
				y1="59.8495"
				x2="196.62"
				y2="-1.00051"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0.24" stopColor="#FF991F" />
				<stop offset="1" stopColor="#FFC400" />
			</linearGradient>
			<linearGradient
				id="related_proj_3_linear"
				x1="191.7"
				y1="187.46"
				x2="206.69"
				y2="172.47"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0.05" stopColor="#4C9AFF" />
				<stop offset="0.99" stopColor="#B2D4FF" />
			</linearGradient>
			<linearGradient
				id="related_proj_4_linear"
				x1="650.616"
				y1="3695.4"
				x2="968.404"
				y2="3377.4"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0.05" stopColor="#4C9AFF" />
				<stop offset="0.99" stopColor="#B2D4FF" />
			</linearGradient>
			<clipPath id="related_proj_0_clip">
				<path d="M0.399902 0.599609H269.3V196.9H0.399902V0.599609Z" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default memo<Props>(EmptyIllustration);
