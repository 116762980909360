import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'business-summary.summary-view.workload-by-assignee.title',
		defaultMessage: 'Team workload',
		description: 'Title of the workload by assignee summary section.',
	},
	description: {
		id: 'business-summary.summary-view.workload-by-assignee.description',
		defaultMessage:
			'Oversee the capacity of your team. {linkStart}Re-assign tasks across your team{linkEnd}',
		description: 'Description of the workload by assignee summary section.',
	},
	descriptionWhenNoIssuesAssigned: {
		id: 'business-summary.summary-view.workload-by-assignee.description-when-no-issues-assigned',
		defaultMessage:
			'Assign and share the workload in your team. {linkStart}Go to the list view{linkEnd}.',
		description:
			'Description of the workload by assignee summary section when no issues in the project are assigned to users.',
	},
	descriptionWhenEmpty: {
		id: 'business-summary.summary-view.workload-by-assignee.description-when-empty',
		defaultMessage:
			"You'll need to create a few items to assign work to the right people in your team.",
		description:
			'Description of the workload by assignee summary section when the project has no issues.',
	},
	assigneeColumnHeader: {
		id: 'business-summary.summary-view.workload-by-assignee.assignee-column-header',
		defaultMessage: 'Assignee',
		description: 'Header of the assignee column.',
	},
	workloadColumnHeader: {
		id: 'business-summary.summary-view.workload-by-assignee.workload-column-header',
		defaultMessage: 'Work distribution',
		description: 'Header of the work distribution column.',
	},
	countColumnHeader: {
		id: 'business-summary.summary-view.workload-by-assignee.count-column-header',
		defaultMessage: 'Count',
		description: 'Header of the count column.',
	},
	unassigned: {
		id: 'business-summary.summary-view.workload-by-assignee.unassigned',
		defaultMessage: 'Unassigned',
		description: 'Label of the workload category for items that do not have an assignee.',
	},
});
