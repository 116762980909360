import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	doneItems: {
		id: 'business-summary.summary-view.overview-items.overview-card.done-items',
		defaultMessage: '{titleStart}{count} done{titleEnd} in the last 7 days',
		description: 'Count of done items in the last 7 days',
	},
	updatedItems: {
		id: 'business-summary.summary-view.overview-items.overview-card.updated-items',
		defaultMessage: '{titleStart}{count} updated{titleEnd} in the last 7 days',
		description: 'Count of updated items in the last 7 days',
	},
	createdItems: {
		id: 'business-summary.summary-view.overview-items.overview-card.created-items',
		defaultMessage: '{titleStart}{count} created{titleEnd} in the last 7 days',
		description: 'Count of created items in the last 7 days',
	},
	dueItems: {
		id: 'business-summary.summary-view.overview-items.overview-card.due-items',
		defaultMessage: '{titleStart}{count} due{titleEnd} in the next 7 days',
		description: 'Count of due items in the next 7 days',
	},
});
