import React from 'react';
import noop from 'lodash/noop';
import EditIcon from '@atlaskit/icon/core/edit';
import EditIconLegacy from '@atlaskit/icon/glyph/edit';
import { Box, Pressable, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { STATUS_NAME_MAX_LENGTH } from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/constants.tsx';
import { useIsWorkflowOperationInProgress } from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/index.tsx';
import { expVal, UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import InlineRenameField from '../../../../common/ui/inline-rename-field/index.tsx';
import { useRenameStatusInline } from '../rename-status/index.tsx';
import { LegendItem, LegendItemColor, LegendItemName, LegendItemCount } from '../styled.tsx';
import type { ChartEntry } from '../utils.tsx';
import messages from './messages.tsx';

type Props = {
	entry: ChartEntry;
	index: number;
	highlightEntry: (dataIndex: number) => void;
	existingStatusNames: string[] | undefined;
	percentage: number;
	linksTarget: '_self' | '_blank';
	onCountClick: () => void;
	canEdit?: boolean;
};

const LegendListItem = ({
	entry,
	index,
	highlightEntry,
	existingStatusNames,
	percentage,
	linksTarget,
	onCountClick,
	canEdit = false,
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const [expConfig] = UNSAFE_noExposureExp('business_summary_page_inline_status');
	const isInlineStatusChangeEnabled = expConfig.get('isInlineStatusChangeEnabled', false);

	const isWorkflowOperationInProgress = isInlineStatusChangeEnabled
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsWorkflowOperationInProgress()
		: false;

	const {
		startEditMode,
		renameStatus,
		validateStatusRename,
		cancelInlineRename,
		editInlineRename,
		editModeIndex,
		isEditing,
		isWorkflowUpdating,
		optimisticStatusName,
	} = isInlineStatusChangeEnabled
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useRenameStatusInline()
		: {
				startEditMode: noop,
				renameStatus: noop,
				validateStatusRename: () => undefined,
				cancelInlineRename: noop,
				editInlineRename: noop,
				editModeIndex: null,
				isEditing: false,
				isWorkflowUpdating: false,
				optimisticStatusName: null,
			};

	return (
		<LegendItem
			onMouseEnter={() => {
				highlightEntry(index);
			}}
		>
			<LegendItemColor color={entry.itemStyle.color} />

			{
				// eslint-disable-next-line no-nested-ternary
				canEdit &&
				expVal('business_summary_page_inline_status', 'isInlineStatusChangeEnabled', false) ? (
					editModeIndex === index ? (
						<Box xcss={inlineRenameWrapperStyles}>
							<InlineRenameField
								defaultValue={entry.name}
								isEditing={isEditing}
								readView={() => (
									<LegendItemName>
										<Box xcss={statusNameStyles}>{optimisticStatusName}</Box>
										{isWorkflowUpdating && (
											<Box xcss={spinnerStyles}>
												<Spinner size="small" label="" />
											</Box>
										)}
									</LegendItemName>
								)}
								onCancel={cancelInlineRename}
								onConfirm={(newName: string) => {
									renameStatus(entry.name, newName, entry.id);
								}}
								onEdit={editInlineRename}
								onValidate={(newName: string) =>
									validateStatusRename(entry.name, newName, existingStatusNames)
								}
								maxLength={STATUS_NAME_MAX_LENGTH}
							/>
						</Box>
					) : (
						<LegendItemName>
							<Box xcss={statusNameStyles}>{entry.name}</Box>
							{!isWorkflowOperationInProgress && (
								<Pressable
									tabIndex={0}
									xcss={iconButtonStyles}
									onClick={() => {
										startEditMode(index);
										fireUIAnalytics(
											createAnalyticsEvent({}),
											'button clicked',
											'statusNameInlineEdit',
										);
									}}
									aria-label={formatMessage(messages.statusNameButtonLabel, {
										statusName: entry.name,
									})}
									onFocus={() => highlightEntry(index)}
									testId="business-summary.ui.summary-view.progress-by-status.legend-item.edit-status-button"
								>
									<Tooltip content={formatMessage(messages.statusNameTooltip)} position="bottom">
										<EditIcon
											label=""
											LEGACY_fallbackIcon={EditIconLegacy}
											LEGACY_size="small"
											color={token('color.icon')}
										/>
									</Tooltip>
								</Pressable>
							)}
						</LegendItemName>
					)
				) : (
					<LegendItemName>{entry.name}</LegendItemName>
				)
			}
			<LegendItemCount
				href={entry.link}
				target={linksTarget}
				onClick={onCountClick}
				onFocus={() => highlightEntry(index)}
				aria-label={formatMessage(messages.tableCountersLabel, {
					issuesCount: entry.value,
					status: entry.name,
					percentage,
				})}
			>
				{entry.value}
			</LegendItemCount>
		</LegendItem>
	);
};

const spinnerStyles = xcss({
	marginLeft: 'space.100',
});

const statusNameStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const iconButtonStyles = xcss({
	borderRadius: 'border.radius',
	outlineOffset: 'space.0',
	padding: 'space.025',
	marginInline: 'space.100',
	opacity: 'var(--edit-opacity, 0)',
	width: 'var(--edit-width, 0)',
	backgroundColor: 'color.background.neutral.subtle',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
});

const inlineRenameWrapperStyles = xcss({
	paddingBlockEnd: 'space.100',
	width: '100%',
});

export default LegendListItem;
