import React, { memo } from 'react';
import { N20A, N30A, N40A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

type Props = {
	className?: string;
	testId: string;
};

const Skeleton = ({ className, testId }: Props) => {
	const skeletonColor = token('color.skeleton', N20A);

	return (
		<svg
			viewBox="0 0 476 296"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={className}
			data-testid={testId}
		>
			<rect width="476" height="8" rx="4" fill={skeletonColor} />
			<rect y="16" width="320" height="8" rx="4" fill={skeletonColor} />
			<path
				d="M240 176C240 242.274 186.274 296 120 296C53.7258 296 0 242.274 0 176C0 109.726 53.7258 56 120 56C186.274 56 240 109.726 240 176ZM60 176C60 209.137 86.8629 236 120 236C153.137 236 180 209.137 180 176C180 142.863 153.137 116 120 116C86.8629 116 60 142.863 60 176Z"
				fill={skeletonColor}
			/>
			<path
				d="M120 56C145.342 56 170.033 64.0226 190.534 78.918C211.036 93.8133 226.296 114.817 234.127 138.918C241.958 163.019 241.958 188.981 234.127 213.082C226.296 237.183 211.036 258.187 190.534 273.082C170.033 287.977 145.341 296 120 296C94.6585 296 69.9675 287.977 49.4658 273.082C28.964 258.187 13.7042 237.183 5.87321 213.082C-1.95774 188.981 -1.95774 163.019 5.87322 138.918L62.9366 157.459C59.0211 169.51 59.0211 182.49 62.9366 194.541C66.8521 206.592 74.482 217.093 84.7329 224.541C94.9837 231.989 107.329 236 120 236C132.671 236 145.016 231.989 155.267 224.541C165.518 217.093 173.148 206.592 177.063 194.541C180.979 182.49 180.979 169.51 177.063 157.459C173.148 145.408 165.518 134.907 155.267 127.459C145.016 120.011 132.671 116 120 116V56Z"
				fill={token('color.skeleton', N30A)}
			/>
			<path
				d="M120 56C138.937 56 157.606 60.4819 174.479 69.0792C191.352 77.6765 205.951 90.1452 217.082 105.466C228.213 120.786 235.56 138.524 238.523 157.228C241.485 175.932 239.979 195.072 234.127 213.082L177.063 194.541C179.989 185.536 180.743 175.966 179.261 166.614C177.78 157.262 174.107 148.393 168.541 140.733C162.975 133.073 155.676 126.838 147.239 122.54C138.803 118.241 129.469 116 120 116L120 56Z"
				fill={token('color.skeleton', N40A)}
			/>
			<rect x="280" y="104" width="24" height="24" rx="4" fill={skeletonColor} />
			<rect x="320" y="112" width="120" height="8" rx="4" fill={skeletonColor} />
			<rect x="280" y="144" width="24" height="24" rx="4" fill={skeletonColor} />
			<rect x="320" y="152" width="120" height="8" rx="4" fill={skeletonColor} />
			<rect x="280" y="184" width="24" height="24" rx="4" fill={skeletonColor} />
			<rect x="320" y="192" width="120" height="8" rx="4" fill={skeletonColor} />
			<rect x="280" y="224" width="24" height="24" rx="4" fill={skeletonColor} />
			<rect x="320" y="232" width="120" height="8" rx="4" fill={skeletonColor} />
		</svg>
	);
};

export default memo<Props>(Skeleton);
