import React, { memo } from 'react';
import { N20A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

type Props = {
	className?: string;
	testId: string;
};

const Skeleton = ({ className, testId }: Props) => {
	const skeletonColor = token('color.skeleton', N20A);

	return (
		<svg
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={className}
			fill="none"
			preserveAspectRatio="none"
			viewBox="0 0 476 328"
			xmlns="http://www.w3.org/2000/svg"
			data-testid={testId}
		>
			<rect y="0" width="476" height="8" rx="4" fill={skeletonColor} />
			<rect y="16" width="320" height="8" rx="4" fill={skeletonColor} />
			<rect y="32" width="48" height="8" rx="4" fill={skeletonColor} />
			<rect y="72" width="20" height="20" rx="4" fill={skeletonColor} />
			<rect x="28" y="81" width="443" height="2" fill={skeletonColor} />
			<rect y="120" width="20" height="20" rx="4" fill={skeletonColor} />
			<rect x="28" y="129" width="443" height="2" fill={skeletonColor} />
			<rect y="168" width="20" height="20" rx="4" fill={skeletonColor} />
			<rect x="28" y="177" width="443" height="2" fill={skeletonColor} />
			<rect y="216" width="20" height="20" rx="4" fill={skeletonColor} />
			<rect x="28" y="225" width="443" height="2" fill={skeletonColor} />
			<rect y="264" width="20" height="20" rx="4" fill={skeletonColor} />
			<rect x="28" y="273" width="443" height="2" fill={skeletonColor} />
			<rect x="50" y="296" width="32" height="8" rx="4" fill={skeletonColor} />
			<rect x="106" y="296" width="32" height="8" rx="4" fill={skeletonColor} />
			<rect x="162" y="296" width="32" height="8" rx="4" fill={skeletonColor} />
			<rect x="218" y="296" width="32" height="8" rx="4" fill={skeletonColor} />
			<rect x="274" y="296" width="32" height="8" rx="4" fill={skeletonColor} />
			<rect x="330" y="296" width="32" height="8" rx="4" fill={skeletonColor} />
			<rect x="386" y="296" width="32" height="8" rx="4" fill={skeletonColor} />
			<rect x="442" y="296" width="32" height="8" rx="4" fill={skeletonColor} />
		</svg>
	);
};
export default memo<Props>(Skeleton);
