import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	morningGreeting: {
		id: 'business-summary.summary-view.header.morning-greeting',
		defaultMessage: 'Good morning, {userName}',
		description: "A morning greeting, personalised with the user's name",
	},
	afternoonGreeting: {
		id: 'business-summary.summary-view.header.afternoon-greeting',
		defaultMessage: 'Good afternoon, {userName}',
		description: "An afternoon greeting, personalised with the user's name",
	},
	eveningGreeting: {
		id: 'business-summary.summary-view.header.evening-greeting',
		defaultMessage: 'Good evening, {userName}',
		description: "An evening greeting, personalised with the user's name",
	},
	description: {
		id: 'business-summary.summary-view.header.description',
		defaultMessage:
			"Here's where you'll view a summary of {containerName}'s status, priorities, workload, and more.",
		description:
			'A description of what can be viewed on this page to track the team and their progress',
	},
});
