import React, { memo, useCallback, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { token } from '@atlaskit/tokens';
import { Collapsible } from '@atlassian/jira-business-collapsible/src/index.tsx';
import {
	ExperienceStart,
	ExperienceErrorBoundary,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { PROJECT_DETAILS_EXPERIENCE } from '../../../common/constants.tsx';
import useProjectMeta from '../../../services/project-meta/index.tsx';
import Content from './content/index.tsx';
import messages from './messages.tsx';

interface Props {
	isSkeleton: boolean;
}

const ProjectDetailsInternal = ({ isSkeleton }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { data, loading, error, fetch } = useProjectMeta();
	const wasFetched = data != null || loading;
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleSection = useCallback(() => {
		if (!isExpanded && !wasFetched) {
			fetch();
		}

		setIsExpanded(!isExpanded);

		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'summaryProjectDetails', {
			expand: !isExpanded,
		});
	}, [createAnalyticsEvent, fetch, isExpanded, wasFetched]);

	const toggleIcon = useMemo(
		() => (isExpanded ? <ChevronUpIcon label="" /> : <ChevronDownIcon label="" />),
		[isExpanded],
	);

	if (isSkeleton) {
		return (
			<UFOLoadHold name="project-details">
				<Wrapper>
					<Skeleton data-testid="business-summary.ui.summary-view.project-details.skeleton" />
				</Wrapper>
			</UFOLoadHold>
		);
	}

	return (
		<Wrapper>
			<ThemedButton
				iconAfter={toggleIcon}
				appearance="subtle-link"
				onClick={toggleSection}
				aria-expanded={isExpanded}
				aria-controls="summary-project-details"
				testId="business-summary.ui.summary-view.project-details.toggle"
			>
				{formatMessage(messages.projectDetails)}
			</ThemedButton>

			<Collapsible id="summary-project-details" isExpanded={isExpanded}>
				<ExperienceStart experience={PROJECT_DETAILS_EXPERIENCE} />
				<Content data={data} error={error} loading={loading} />
			</Collapsible>
		</Wrapper>
	);
};

const ProjectDetails = (props: Props) => (
	<UFOSegment name="business-summary-project-details">
		<ExperienceErrorBoundary experience={PROJECT_DETAILS_EXPERIENCE} fallback="unmount">
			<ProjectDetailsInternal {...props} />
		</ExperienceErrorBoundary>
	</UFOSegment>
);

export default memo<Props>(ProjectDetails);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Skeleton = styled.div({
	width: '160px',
	borderRadius: '4px',
	margin: `${token('space.050', '4px')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backgroundColor: Tokens.COLOR_SKELETON,
});
