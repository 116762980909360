import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'business-summary.summary-view.related-projects.title',
		defaultMessage: 'Related projects',
		description: 'Title of the related projects summary section.',
	},
	description: {
		id: 'business-summary.summary-view.related-projects.description',
		defaultMessage:
			'Connect with other projects your team frequently contributes to and align with other project dependencies.',
		description: 'Description of the related projects summary section.',
	},
	descriptionWhenEmpty: {
		id: 'business-summary.summary-view.related-projects.description-when-empty',
		defaultMessage:
			'Use projects to manage all your work in one place and stay aligned with stakeholders.',
		description:
			'Description of the related projects summary section when there are no related projects.',
	},
	createProject: {
		id: 'business-summary.summary-view.related-projects.create-project',
		defaultMessage: 'Create a project',
		description: 'Label of a button allowing the user to start the create project flow.',
	},
	viewAllProjects: {
		id: 'business-summary.summary-view.related-projects.view-all-projects',
		defaultMessage: 'View all projects',
		description: 'Label of a link to view all projects.',
	},
});
