import { ISSUES_BY_TYPE_FRAGMENT } from '@atlassian/jira-business-summary-services/src/services/constants.tsx';

export const ISSUES_BY_TYPE_QUERY = `
  query JiraBusinessIssuesByTypeData($jql: String!, $pageSize: Int!, $after: String) {
    issuesByType: jwmItemGroups(jql: $jql, first: $pageSize, after: $after, fieldIds: [], groupByFieldId: "issuetype", sortBy: {field: GROUP_COUNT, direction: DESC}) {
      ...IssuesByTypeFragment
    }
  }

  ${ISSUES_BY_TYPE_FRAGMENT}
`;
