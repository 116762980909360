import { useCallback, useRef } from 'react';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { Section, SectionPosition } from './types.tsx';

type LinkTarget =
	| 'view'
	| 'viewWithFilter'
	| 'user'
	| 'issue'
	| 'createProject'
	| 'projectDirectory'
	| 'relatedProject'
	| 'manageTypes'
	| 'inviteUser'
	| 'addIssueTypes'
	| 'createStatus'
	| 'tipsForHierarcyEducationIssueTypes';

export const useSectionAnalytics = (position: SectionPosition, sectionId: Section) => {
	const hasFiredAnalyticsEventRef = useRef(false);
	const hoverStartTimeRef = useRef(0);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const startHoverTimer = useCallback(() => {
		if (!hasFiredAnalyticsEventRef.current) {
			hoverStartTimeRef.current = new Date().getTime();
		}
	}, [hasFiredAnalyticsEventRef]);

	const stopHoverTimer = useCallback(() => {
		if (hasFiredAnalyticsEventRef.current) {
			return;
		}

		const secondsHovered = Math.floor((new Date().getTime() - hoverStartTimeRef.current) / 1000);

		if (secondsHovered >= 3) {
			fireUIAnalytics(createAnalyticsEvent({}), 'section hovered', 'summarySection', {
				position,
				sectionId,
				secondsHovered,
			});

			hasFiredAnalyticsEventRef.current = true;
		}
	}, [createAnalyticsEvent, position, sectionId]);

	const fireSectionAnalytics = useCallback(
		(
			target: LinkTarget,
			attributes?: {
				[key: string]: string | boolean | number;
			},
		) =>
			fireUIAnalytics(createAnalyticsEvent({}), 'item clicked', 'summarySection', {
				...attributes,
				position,
				sectionId,
				target,
			}),
		[position, sectionId, createAnalyticsEvent],
	);

	return { startHoverTimer, stopHoverTimer, fireSectionAnalytics };
};

export const getCleanIssueTypesName = (name: string): string => name.replace(/\s{2,}/g, ' ').trim();
