import { useCallback } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import {
	useExperienceStart,
	useExperienceFail,
	useExperienceSuccess,
	useExperienceAbort,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import type { IssueTypeType } from '@atlassian/jira-business-summary-services/src/services/types.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { showFlag } from '@atlassian/jira-flags/src/services';
import { useIntl } from '@atlassian/jira-intl';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics';
import { RENAME_ISSUE_TYPE_EXPERIENCE } from '../../common/constants.tsx';
import useFetchIssueLayoutConfiguration from '../../services/issue-layout-configuration/index.tsx';
import updateIssueType from '../../services/update-issue-type/index.tsx';
import messages from './messages.tsx';

export const useRenameIssueType = (issueType: IssueTypeType) => {
	const { formatMessage } = useIntl();
	const project = useProject();
	const fetchIssueLayoutConfiguration = useFetchIssueLayoutConfiguration(project.id, issueType.id);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const startExperience = useExperienceStart(RENAME_ISSUE_TYPE_EXPERIENCE);
	const markExperienceSuccess = useExperienceSuccess(RENAME_ISSUE_TYPE_EXPERIENCE);
	const markExperienceFailed = useExperienceFail(RENAME_ISSUE_TYPE_EXPERIENCE);
	const abortExperience = useExperienceAbort(RENAME_ISSUE_TYPE_EXPERIENCE);

	return useCallback(
		async (name: string, refetchIssueTypes: () => void) => {
			try {
				startExperience();
				const issueLayoutConfiguration = await fetchIssueLayoutConfiguration();

				if (!issueLayoutConfiguration) {
					throw new Error(
						`cannot find issue layout configuration for project: ${project.id} and issue type: ${issueType.id}`,
					);
				}

				await updateIssueType(project.id, issueLayoutConfiguration.id, Number(issueType.id), {
					owners: [
						{
							data: {
								...issueLayoutConfiguration.owners,
								name,
								iconUrl: issueType.iconUrl,
								id: Number(issueType.id),
							},
							type: 'ISSUE_TYPE',
						},
					],
				});
				await refetchIssueTypes();

				markExperienceSuccess();
				fireTrackAnalytics(createAnalyticsEvent({}), 'issueType updated');
			} catch (saveIssueTypeError: unknown) {
				if (saveIssueTypeError instanceof FetchError && saveIssueTypeError.statusCode === 409) {
					showFlag({
						type: 'error',
						title: formatMessage(messages.errorFlagTitleRenameIssueType),
						description: formatMessage(messages.issueTypeNameAlreadyInUse),
					});
					abortExperience('Issue type name already exists');
					return;
				}

				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				markExperienceFailed('Failed to rename issue type', saveIssueTypeError as Error);

				fireErrorAnalytics({
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					error: saveIssueTypeError as Error,
					meta: {
						id: 'business-summary-types-of-work-rename-error',
						packageName: 'jiraBusinessSummary',
					},
				});
				showFlag({
					type: 'error',
					title: formatMessage(messages.errorFlagTitleRenameIssueType),
					description: formatMessage(messages.issueTypeNameNetworkError),
				});
			}
		},
		[
			project.id,
			issueType.id,
			issueType.iconUrl,
			startExperience,
			abortExperience,
			markExperienceSuccess,
			markExperienceFailed,
			fetchIssueLayoutConfiguration,
			formatMessage,
			createAnalyticsEvent,
		],
	);
};
