import { styled } from '@compiled/react';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { Link } from '@atlassian/react-resource-router';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ThemedLink = styled(Link)({
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-link': Tokens.COLOR_LINK,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-link-pressed': Tokens.COLOR_LINK_PRESSED,
});
