import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	inviteFeedbackMessage: {
		id: 'business-summary.summary-view.feedback-banner.invite-feedback-message',
		defaultMessage: 'Was the information shown in this page useful? ',
		description: 'Message in the summary view footer, if user wants to give any feedback',
	},
	submitFeedbackCallToAction: {
		id: 'business-summary.summary-view.feedback-banner.submit-feedback-call-to-action',
		defaultMessage: 'Give us feedback',
		description:
			'Message for a link that will show feedback collector for user to submit feedback regarding summary view',
	},
});
