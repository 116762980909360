import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Skeleton from './skeleton/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Description = styled.p({
	margin: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledSkeleton = styled(Skeleton)({
	marginTop: token('space.200', '16px'),
});
