/* eslint-disable jira/react/no-style-attribute */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import { Flex, xcss } from '@atlaskit/primitives';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { BOARD_ISSUES_JQL } from '@atlassian/jira-business-summary-services/src/services/constants.tsx';
import { UNSAFE_noExposureExp, expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { JWM_SUMMARY_VIEW_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants.tsx';
import { useIsTailoredOnboardingM2ForQuickstartEnabled } from '@atlassian/jira-onboarding-core/src/controllers/use-fetch-onboarding-personalisation/index.tsx';
import { useRegisterNudgeTarget } from '@atlassian/jira-software-onboarding-nudges--next/src/common/ui/do-not-use-directly-portalled-nudge/index.tsx';
import { SummaryViewNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/jwm-summary-view/async.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import type { SectionProps } from '../../../common/types.tsx';
import AccessibleLink from '../../../common/ui/accessible-link/index.tsx';
import Card from '../../../common/ui/card/index.tsx';
import CreateItemLink from '../../../common/ui/create-item-link/index.tsx';
import { useSectionAnalytics } from '../../../common/utils.tsx';
import { useSummaryFeatures } from '../../../controllers/features-context/index.tsx';
import {
	useIssuesByStatus,
	useWorkflowsCount,
} from '../../../controllers/summary-context/index.tsx';
import { useInlineStatusEligibilityCheck } from '../../../controllers/use-inline-status-eligibility-check/index.tsx';
import { CreateStatusButton } from './create-status-button/index.tsx';
import LegendListItem from './legend-item/index.tsx';
import messages from './messages.tsx';
import {
	CenteredSkeleton,
	Description,
	ChartWrapper,
	Chart,
	EmptyChart,
	Legend,
	LegendList,
	LegendItemName,
	LegendItemCount,
	LegendTotal,
} from './styled.tsx';
import { buildChartData } from './utils.tsx';

echarts.use([PieChart, SVGRenderer]);

const CHART_STYLE = { height: '240px' } as const;
const CHART_OPTIONS = { renderer: 'svg' } as const;

const calculatePercentage = (currentEntryCount: number, chartTotalCount: number) =>
	Math.round((currentEntryCount / (chartTotalCount === 0 ? 1 : chartTotalCount)) * 100);

const ProgressByStatus = ({ position }: SectionProps) => {
	// Type of `EChartsInstance` is `any` within the `echarts-for-react` package.
	// Importing `EChartsInstance` from `echarts-for-react` introduces an additional 1.91MB to the bundle just to import an `any`.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [echartsInstance, setEchartsInstance] = useState<any>(null);
	const { formatMessage } = useIntl();
	const { data, loading, refresh } = useIssuesByStatus();
	const { hasCreateItem, linksTarget, issuesLinkUrl } = useSummaryFeatures();
	const { push } = useRouterActions();
	const isEnabledForTailoredOnboardingM2 = fg('jira_tailored_onboarding_jtbd_feature_gate')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsTailoredOnboardingM2ForQuickstartEnabled()
		: false;

	const { startHoverTimer, stopHoverTimer, fireSectionAnalytics } = useSectionAnalytics(
		position,
		'progressByStatus',
	);

	const [expConfig] = UNSAFE_noExposureExp('business_summary_page_inline_status');
	const isInlineStatusChangeEnabled = expConfig.get('isInlineStatusChangeEnabled', false);

	const { data: workflowsData } = isInlineStatusChangeEnabled
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useWorkflowsCount()
		: { data: null };

	const { isEligibleForCreateStatus, isEligibleforEditStatus } = fg(
		'business_summary_page_inline_status_targeting_gate',
	)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useInlineStatusEligibilityCheck()
		: { isEligibleForCreateStatus: false, isEligibleforEditStatus: false };

	const hasMultipleWorkflows = (workflowsData?.totalCount ?? 0) > 1;

	const chartData = useMemo(() => buildChartData(data, issuesLinkUrl), [data, issuesLinkUrl]);

	const existingStatusNames = isInlineStatusChangeEnabled
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(() => chartData?.entries.map((entry) => entry.name) ?? [], [chartData])
		: [];

	const chartEvents = useMemo(
		() => ({
			click: ({ dataIndex }: { dataIndex: number }) => {
				if (chartData != null) {
					const { value, categoryKey, link } = chartData.entries[dataIndex];
					if (linksTarget === '_self') {
						push(link);
					} else {
						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						window.open(link, '_blank');
					}
					fireSectionAnalytics('viewWithFilter', {
						count: value,
						statusCategory: categoryKey,
					});
				}
			},
		}),
		[chartData, fireSectionAnalytics, push, linksTarget],
	);

	const highlightEntry = useCallback(
		(dataIndex: number) => {
			if (echartsInstance) {
				echartsInstance.dispatchAction({ type: 'downplay' });
				echartsInstance.dispatchAction({ type: 'highlight', dataIndex });
			}
		},
		[echartsInstance],
	);

	const handleCreateStatusButtonClick = useCallback(() => {
		fireSectionAnalytics('createStatus');
	}, [fireSectionAnalytics]);

	useEffect(() => {
		if (echartsInstance && chartData) {
			highlightEntry(chartData.initialHighlight);

			// and set the mouse overed section to be highlighted after that
			echartsInstance.on('mouseover', 'series', ({ dataIndex }: { dataIndex: number }) =>
				highlightEntry(dataIndex),
			);

			return () => echartsInstance.off('mouseover');
		}
	}, [chartData, highlightEntry, echartsInstance]);

	const viewAllLink = useMemo(
		() => `${issuesLinkUrl}?filter=${encodeURIComponent(BOARD_ISSUES_JQL)}&hideDone=false`,
		[issuesLinkUrl],
	);

	let content = null;

	if (!loading && chartData != null) {
		const isEmpty = chartData.totalCount === 0;

		const legendContent = (
			<Legend>
				<LegendList>
					{chartData.entries.map((entry, index) => (
						<LegendListItem
							key={entry.name}
							entry={entry}
							index={index}
							highlightEntry={highlightEntry}
							existingStatusNames={existingStatusNames}
							percentage={calculatePercentage(entry.value, chartData.totalCount)}
							linksTarget={linksTarget}
							onCountClick={() =>
								fireSectionAnalytics('viewWithFilter', {
									count: entry.value,
									statusCategory: entry.categoryKey,
								})
							}
							canEdit={isEligibleforEditStatus}
						/>
					))}
				</LegendList>
				<LegendTotal>
					<LegendItemName>{formatMessage(messages.total)}</LegendItemName>

					<LegendItemCount
						href={viewAllLink}
						target={linksTarget}
						onClick={() =>
							fireSectionAnalytics('view', {
								count: chartData.totalCount,
							})
						}
						aria-label={formatMessage(messages.totalCountWithLabel, {
							issuesCount: chartData.totalCount,
						})}
					>
						{chartData.totalCount}
					</LegendItemCount>
				</LegendTotal>
			</Legend>
		);

		content = (
			<>
				<Description>
					{isEmpty ? (
						<>
							{formatMessage(messages.descriptionWhenEmpty)}
							{hasCreateItem && (
								<>
									{' '}
									<CreateItemLink
										href={`${issuesLinkUrl}?inlineCreate=true`}
										onClick={() =>
											fireSectionAnalytics('view', {
												inlineCreate: true,
											})
										}
										target={linksTarget}
									/>
								</>
							)}
						</>
					) : (
						<FormattedI18nMessage
							message={formatMessage(messages.description, {
								linkStart: '{linkStart}',
								linkEnd: '{linkEnd}',
							})}
							componentsMapping={{
								link: ({ children }) => (
									<AccessibleLink
										href={viewAllLink}
										target={linksTarget}
										onClick={() =>
											fireSectionAnalytics('view', {
												inlineCreate: false,
											})
										}
										aria-label={formatMessage(messages.viewAllItemsLabel)}
									>
										{children}
									</AccessibleLink>
								),
							}}
						/>
					)}
				</Description>
				<ChartWrapper>
					{isEmpty ? (
						<EmptyChart testId="business-summary.ui.summary-view.progress-by-status.empty-chart" />
					) : (
						<Chart
							onChartReady={setEchartsInstance}
							echarts={echarts}
							option={chartData.option}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							style={CHART_STYLE}
							opts={CHART_OPTIONS}
							onEvents={chartEvents}
						/>
					)}
					{isEligibleForCreateStatus &&
					expVal('business_summary_page_inline_status', 'isInlineStatusChangeEnabled', false) ? (
						<Flex direction="column" xcss={legendContainerStyles}>
							{legendContent}
							<CreateStatusButton
								onClick={handleCreateStatusButtonClick}
								statusNames={existingStatusNames}
								hasMultipleWorkflows={hasMultipleWorkflows}
								onCreateStatusSuccess={refresh}
							/>
						</Flex>
					) : (
						legendContent
					)}
				</ChartWrapper>
			</>
		);
	} else {
		content = (
			<UFOLoadHold name="progress-by-status">
				<CenteredSkeleton testId="business-summary.ui.summary-view.progress-by-status.skeleton" />
			</UFOLoadHold>
		);
	}
	if (isEnabledForTailoredOnboardingM2) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { ref: summaryViewNudgeRef } = useRegisterNudgeTarget<HTMLDivElement>(
			JWM_SUMMARY_VIEW_NUDGE_ID,
			true,
		);

		return (
			<UFOSegment name="business-summary-progress-by-status">
				<Card
					ref={summaryViewNudgeRef}
					title={formatMessage(messages.title)}
					isLoading={loading}
					testId="business-summary.ui.summary-view.progress-by-status"
					onPointerEnter={startHoverTimer}
					onPointerLeave={stopHoverTimer}
				>
					{content}
				</Card>
				<SummaryViewNudgeAsync />
			</UFOSegment>
		);
	}

	return (
		<UFOSegment name="business-summary-progress-by-status">
			<Card
				title={formatMessage(messages.title)}
				isLoading={loading}
				testId="business-summary.ui.summary-view.progress-by-status"
				onPointerEnter={startHoverTimer}
				onPointerLeave={stopHoverTimer}
			>
				{content}
			</Card>
		</UFOSegment>
	);
};

const legendContainerStyles = xcss({
	flex: '1 1 50%',
	maxHeight: '100%',
	minWidth: '0',
});
export default memo<SectionProps>(ProgressByStatus);
