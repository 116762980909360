import { useCallback, useReducer } from 'react';
import { useRenameStatus } from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/rename-status/index.tsx';
import {
	fireTrackAnalytics,
	useAnalyticsEvents,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { RENAME_STATUS_EXPERIENCE } from '../../../../common/constants.tsx';
import { useSummaryContext } from '../../../../controllers/summary-context/index.tsx';

type RenameState = {
	editModeIndex: number | null;
	optimisticStatusName: string | null;
	isWorkflowUpdating: boolean;
	isEditing: boolean;
};

type RenameAction =
	| { type: 'START_STATUS_RENAME'; payload: string | null }
	| { type: 'RESET_EDIT_STATE' }
	| { type: 'SET_EDIT_MODE'; payload: boolean }
	| { type: 'START_EDIT_MODE'; payload: number };

const renameInitialState: RenameState = {
	editModeIndex: null,
	optimisticStatusName: null,
	isWorkflowUpdating: false,
	isEditing: false,
};

const reducer = (state: RenameState, action: RenameAction): RenameState => {
	switch (action.type) {
		case 'START_STATUS_RENAME':
			return {
				...state,
				isWorkflowUpdating: true,
				optimisticStatusName: action.payload,
				isEditing: false,
			};
		case 'RESET_EDIT_STATE':
			return {
				...renameInitialState,
			};
		case 'SET_EDIT_MODE':
			return { ...state, isEditing: action.payload };
		case 'START_EDIT_MODE':
			return { ...state, editModeIndex: action.payload, isEditing: true };
		default:
			return state;
	}
};

export const useRenameStatusInline = () => {
	const { renameStatus, validateRenameStatus } = useRenameStatus(RENAME_STATUS_EXPERIENCE);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { refresh: refreshSummaryData, data: issuesByTypeData } = useSummaryContext();

	const [renameState, dispatch] = useReducer(reducer, renameInitialState);

	const setStartStatusRename = useCallback(
		(newName: string) => {
			dispatch({ type: 'START_STATUS_RENAME', payload: newName });
		},
		[dispatch],
	);
	const resetEditState = useCallback(() => dispatch({ type: 'RESET_EDIT_STATE' }), [dispatch]);
	const setIsEditing = useCallback(
		(isEditMode: boolean) => dispatch({ type: 'SET_EDIT_MODE', payload: isEditMode }),
		[dispatch],
	);
	const startEditMode = useCallback(
		(statusRowIndex: number) => {
			dispatch({ type: 'START_EDIT_MODE', payload: statusRowIndex });
		},
		[dispatch],
	);

	const renameStatusInternal = useCallback(
		async (oldName: string, newName: string, statusId: number | null) => {
			if (oldName === newName || statusId == null) {
				resetEditState();
				return;
			}
			const issueTypeId = issuesByTypeData?.issuesByType?.data.map(
				(issue) => issue.issueType.id,
			)[0];

			if (issueTypeId != null) {
				try {
					setStartStatusRename(newName);
					await renameStatus({
						oldName,
						statusId,
						newName,
						issueTypeId,
					});
					resetEditState();
					fireTrackAnalytics(createAnalyticsEvent({}), 'workflow saved');
				} catch (error) {
					resetEditState();
					throw error;
				} finally {
					refreshSummaryData();
				}
			}
		},
		[
			issuesByTypeData?.issuesByType?.data,
			resetEditState,
			setStartStatusRename,
			renameStatus,
			createAnalyticsEvent,
			refreshSummaryData,
		],
	);

	const validateStatusRenameInternal = useCallback(
		(oldName: string, newName: string, existingStatusNames: string[] | undefined) => {
			if (!existingStatusNames) return;

			return validateRenameStatus({
				oldName,
				newName,
				existingStatusNames,
			});
		},
		[validateRenameStatus],
	);

	const editInlineRename = useCallback(() => {
		setIsEditing(true);
	}, [setIsEditing]);

	const cancelInlineRename = useCallback(() => {
		resetEditState();
		fireUIAnalytics(createAnalyticsEvent({}), 'statusNameInlineEdit cancelled');
	}, [createAnalyticsEvent, resetEditState]);

	return {
		renameStatus: renameStatusInternal,
		validateStatusRename: validateStatusRenameInternal,
		cancelInlineRename,
		editInlineRename,
		startEditMode,
		isWorkflowUpdating: renameState.isWorkflowUpdating,
		isEditing: renameState.isEditing,
		editModeIndex: renameState.editModeIndex,
		optimisticStatusName: renameState.optimisticStatusName,
	};
};
