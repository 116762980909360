import parameters, {
	type srcAsyncCreateIssueTypeQuery$variables,
} from '@atlassian/jira-relay/src/__generated__/srcAsyncCreateIssueTypeQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const asyncCreateIssueTypeModalEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-create-issue-type-modal-entrypoint" */ './src'),
	),
	getPreloadProps: ({ projectKey, cloudId }: srcAsyncCreateIssueTypeQuery$variables) => ({
		queries: {
			createIssueTypeModal: {
				options: { fetchPolicy: 'store-and-network' as const },
				parameters,
				variables: { projectKey, cloudId },
			},
		},
	}),
});
