import React, { createContext, useMemo, useContext, type ReactNode } from 'react';

type RequiredProps = {
	issuesLinkUrl: string;
};

type OptionalProps = {
	featureView: 'summary' | 'summary-embed';
	hasCreateItem: boolean;
	hasFeedbackFooter: boolean;
	hasHeader: boolean;
	hasInviteTeammateButton: boolean;
	hasManageTypesButton: boolean;
	hasShareButton: boolean;
	linksTarget: '_self' | '_blank';
};

type ContextProps = RequiredProps &
	Partial<OptionalProps> & {
		children: ReactNode;
	};

type FeaturesContext = RequiredProps & OptionalProps;

const defaultFeaturesContext: OptionalProps = {
	featureView: 'summary',
	hasCreateItem: true,
	hasFeedbackFooter: true,
	hasHeader: true,
	hasInviteTeammateButton: true,
	hasManageTypesButton: true,
	hasShareButton: true,
	linksTarget: '_self',
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
const SummaryFeaturesContext = createContext<FeaturesContext>(null as any);

const useSummaryFeatures = () => useContext(SummaryFeaturesContext);

const SummaryFeaturesProvider = ({ children, ...props }: ContextProps) => {
	const value = useMemo(() => ({ ...defaultFeaturesContext, ...props }), [props]);

	return (
		<SummaryFeaturesContext.Provider value={value}>{children}</SummaryFeaturesContext.Provider>
	);
};

export default SummaryFeaturesProvider;
export { useSummaryFeatures };
