import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	statusAlreadyExists: {
		id: 'business-workflows.controllers.workflow-actions.rename-status.status-already-exists',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: "''{statusName}'' is already a status name in this project",
		description:
			'Error message to be shown to user when user trying to rename status into status that already exists',
	},
	emptyStatusNameError: {
		id: 'business-workflows.controllers.workflow-actions.rename-status.empty-status-name-error',
		defaultMessage: 'Status name cannot be empty',
		description:
			'Error message to be shown to user when user trying to rename status into empty status',
	},
	errorFlagTitleRenameStatus: {
		id: 'business-workflows.controllers.workflow-actions.rename-status.error-flag-title-rename-status',
		defaultMessage: 'Unable to rename status',
		description: 'Title of error flag that is shown if rename status is unsuccessful',
	},
});
