import { useRef, useEffect, useState, useCallback } from 'react';
import throttle from 'lodash/throttle';
import { useSidebar } from '../sidebar/index.tsx';

const THROTTLE_TIME = 16;

export const useResizer = () => {
	const [{ sidebarWidth }, { setSidebarWidth }] = useSidebar();
	const startRef = useRef(0);
	// storing width in a ref to avoid re-creating onMouseMove handler during resize
	const width = useRef(0);
	const [isResizing, setIsResizing] = useState(false);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const resize = useCallback(
		throttle((clientX) => {
			const proposedWidth = width.current + startRef.current - clientX;
			setSidebarWidth(proposedWidth);
		}, THROTTLE_TIME),
		[setSidebarWidth],
	);

	const onMouseMove = useCallback(
		(event: MouseEvent) => {
			event.preventDefault();
			resize(event.clientX);
		},
		[resize],
	);

	const onMouseUp = useCallback(() => {
		startRef.current = 0;
		width.current = 0;
		setIsResizing(false);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.removeEventListener('mousemove', onMouseMove);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.removeEventListener('mouseup', onMouseUp);
	}, [onMouseMove]);

	useEffect(
		() => () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('mousemove', onMouseMove);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('mouseup', onMouseUp);
		},
		[onMouseMove, onMouseUp],
	);

	const onMouseDown = useCallback(
		(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
			startRef.current = event.clientX;
			width.current = sidebarWidth;
			setIsResizing(true);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.addEventListener('mousemove', onMouseMove);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.addEventListener('mouseup', onMouseUp);
		},
		[onMouseMove, onMouseUp, sidebarWidth],
	);

	return { onMouseDown, onMouseUp, onMouseMove, isResizing };
};
