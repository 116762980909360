import React, { memo } from 'react';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/index.tsx';
import { useBusinessEntity } from '../../controllers/business-entity-context/index.tsx';

type BusinessDocumentTitleProps = {
	pageHeading: string;
};

// Wrapper around @atlassian/jira-global-document-title that
// keeps business document title logic
export const BusinessDocumentTitle = memo<BusinessDocumentTitleProps>(
	({ pageHeading }: BusinessDocumentTitleProps) => {
		const { data: entity } = useBusinessEntity();

		const documentTitle = entity?.name ? `${pageHeading} - ${entity.name}` : pageHeading;

		return <DocumentTitle title={documentTitle} />;
	},
);
