/* eslint-disable max-classes-per-file */
export class StatusNameIsNotUniqueError extends Error {
	constructor() {
		super('status name is not unique');
		this.name = 'StatusNameIsNotUniqueError';
	}
}

export class StatusHasNoIncomingTransitionError extends Error {
	constructor() {
		super('status has no incoming transition error');
		this.name = 'StatusHasNoIncomingTransitionError';
	}
}

export class WorkflowVersionConflictError extends Error {
	constructor() {
		super('Workflow version and version token must match');
		this.name = 'WorkflowVersionConflictError';
	}
}

export class OldStatusMissingInWorkflowError extends Error {
	constructor() {
		super('Old status missing in workflow');
		this.name = 'OldStatusMissingInWorkflowError';
	}
}

export class NewStatusMissingInWorkflowError extends Error {
	constructor() {
		super('New status missing in workflow');
		this.name = 'NewStatusMissingInWorkflowError';
	}
}

export class OnlyOneInitialTransitionAllowedError extends Error {
	constructor() {
		super('Workflow must have exactly one initial transition');
		this.name = 'OnlyOneInitialTransitionAllowedError';
	}
}

export class ApprovalRuleMisconfiguredError extends Error {
	constructor() {
		super('Approval status has invalid transitions');
		this.name = 'ApprovalRuleMisconfiguredError';
	}
}
