import React, { memo, useCallback, type ReactElement } from 'react';
import { styled, css } from '@compiled/react';
import Add from '@atlaskit/icon/core/migration/add--editor-add';
import Calendar from '@atlaskit/icon/core/migration/calendar';
import CheckMark from '@atlaskit/icon/core/migration/check-mark--editor-done';
import Edit from '@atlaskit/icon/core/migration/edit--edit-filled';
import type { NewGlyphColorProps } from '@atlaskit/icon/src/types';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { colors } from '@atlaskit/theme';
import { N0, N20A, N40A, N50A, N60A, N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { Link } from '@atlassian/react-resource-router';
import { CARD_BORDER_RADIUS } from '../../../../common/constants.tsx';
import { useSummaryFeatures } from '../../../../controllers/features-context/index.tsx';
import { DONE, UPDATED, CREATED, DUE } from './constants.tsx';
import messages from './messages.tsx';

type Card = {
	color: NewGlyphColorProps['color'];
	backgroundColor: string;
	getIcon: (color: NewGlyphColorProps['color']) => ReactElement;
	message: MessageDescriptorV2;
	getLink: (issuesLinkUrl: string) => string;
};

type CardTypes = 'done' | 'updated' | 'created' | 'due';

const EMPTY_ICON_COLOR = token('color.icon', colors.N500);
const EMPTY_BACKGROUND_COLOR = token('color.background.neutral', colors.N20A);

const CARD_TYPES: Record<CardTypes, Card> = {
	[DONE]: {
		color: token('color.text.accent.green', colors.G500),
		backgroundColor: token('color.background.accent.green.subtler', colors.G50),
		getIcon: (color: NewGlyphColorProps['color']) => (
			<CheckMark label="" color={color} LEGACY_primaryColor={color} />
		),
		message: messages.doneItems,
		getLink: (issuesLinkUrl) =>
			`${issuesLinkUrl}?filter=${encodeURIComponent(
				'statusCategory = Done AND statusCategoryChangedDate >= -1w',
			)}&hideDone=false`,
	},
	[UPDATED]: {
		color: token('color.text.accent.blue', colors.B400),
		backgroundColor: token('color.background.accent.blue.subtler', colors.B50),
		getIcon: (color) => <Edit label="" color={color} LEGACY_primaryColor={color} />,
		message: messages.updatedItems,
		getLink: (issuesLinkUrl) =>
			`${issuesLinkUrl}?filter=${encodeURIComponent('updatedDate >= -1w')}&hideDone=false`,
	},
	[CREATED]: {
		color: token('color.text.accent.purple', colors.P300),
		backgroundColor: token('color.background.accent.purple.subtler', colors.P50),
		getIcon: (color) => <Add label="" color={color} LEGACY_primaryColor={color} />,
		message: messages.createdItems,
		getLink: (issuesLinkUrl) =>
			`${issuesLinkUrl}?filter=${encodeURIComponent('createdDate >= -1w')}&hideDone=false`,
	},
	[DUE]: {
		color: token('color.text.accent.red', colors.R400),
		backgroundColor: token('color.background.accent.red.subtler', colors.R50),
		getIcon: (color) => <Calendar label="" color={color} LEGACY_primaryColor={color} />,
		message: messages.dueItems,
		getLink: (issuesLinkUrl) =>
			`${issuesLinkUrl}?filter=${encodeURIComponent(
				'statusCategory != Done AND (duedate >= 0d AND duedate < 1w)',
			)}`,
	},
};

export type Props = {
	isLoading: boolean;
	count?: number;
	type: CardTypes;
	testId: string;
};

const OverviewCard = ({ type, testId, count, isLoading }: Props) => {
	const { formatMessage } = useIntl();
	const { linksTarget, issuesLinkUrl } = useSummaryFeatures();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isEmpty = count === 0;

	const card = CARD_TYPES[type];
	const { message, color, backgroundColor, getIcon, getLink } = card;

	const cardColor = isEmpty ? null : color;
	const cardBackgroundColor = isEmpty ? EMPTY_BACKGROUND_COLOR : backgroundColor;
	const icon = getIcon(isEmpty ? EMPTY_ICON_COLOR : color);

	const celebrateEmoji = type === DONE && !isEmpty ? '  🎉' : null;

	const onClick = useCallback(
		() =>
			fireUIAnalytics(createAnalyticsEvent({}), 'link clicked', 'summaryOverviewCard', {
				type,
				count,
			}),
		[type, count, createAnalyticsEvent],
	);

	return isLoading ? (
		<UFOLoadHold name="overview-card">
			<CardItem>
				<SkeletonCardContainer data-testid={testId}>
					<Skeleton testId="business-summary.ui.summary-view.overview-items.overview-card.skeleton" />
				</SkeletonCardContainer>
			</CardItem>
		</UFOLoadHold>
	) : (
		<CardItem>
			<CardContainer
				href={getLink(issuesLinkUrl)}
				target={linksTarget}
				data-testid={testId}
				onClick={onClick}
			>
				<CardIconContainer color={cardBackgroundColor}>
					<IconWrapper>{icon}</IconWrapper>
				</CardIconContainer>
				<ContentContainer>
					<FormattedI18nMessage
						message={formatMessage(message, {
							titleStart: '{titleStart}',
							titleEnd: '{titleEnd}',
							count,
						})}
						componentsMapping={{
							// @ts-expect-error(PARTIAL_RECORD) TS2322 - Type 'string | null' is not assignable to type 'string'.
							title: ({ children }) => <Title color={cardColor}>{children}</Title>,
						}}
					/>
					{celebrateEmoji}
				</ContentContainer>
			</CardContainer>
		</CardItem>
	);
};

export default memo<Props>(OverviewCard);

const SkeletonSVG = ({ className, testId }: { className?: string; testId: string }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 264 99"
		fill="none"
		preserveAspectRatio="none"
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		className={className}
		data-testid={testId}
	>
		<g>
			<rect x="25" y="25" width="48" height="48" rx="24" fill={token('color.skeleton', N20A)} />
			<rect x="85" y="29" width="83" height="24" rx="4" fill={token('color.skeleton', N20A)} />
			<rect x="85" y="61" width="154" height="8" rx="4" fill={token('color.skeleton', N20A)} />
		</g>
	</svg>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.span({
	display: 'flex',
	transition: 'transform 150ms ease-in-out 0s',
	transform: 'var(--icon-transform)',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardItem = styled.li({
	margin: 0,
	height: 'fit-content',
	boxSizing: 'border-box',
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardIconContainer = styled.span<{ color: string }>({
	height: '48px',
	width: '48px',
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ color }) => color,
	flex: 'none',
});

const cardContainerBase = css({
	height: '96px',
	display: 'flex',
	flex: '1',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${CARD_BORDER_RADIUS}px`,
	boxSizing: 'border-box',
	backgroundColor: token('elevation.surface.raised', N0),
	transition: 'box-shadow 300ms ease 0s',
	boxShadow: token('elevation.shadow.raised', `0 1px 1px ${N50A}, 0 0 1px 1px ${N40A}`),
	'--icon-transform': 'none',
	'&:hover, &:active, &:focus': {
		boxShadow: token('elevation.shadow.overlay', `0px 8px 12px ${N40A}, 0px 0px 1px ${N60A}`),
		'--icon-transform': 'scale(1.2)',
	},
	'&:focus, &:visited, &:hover, &:active': {
		textDecoration: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardContainer = styled(Link)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...cardContainerBase,
	padding: token('space.300', '24px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonCardContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...cardContainerBase,
	padding: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.span({
	display: 'flex',
	flexDirection: 'column',
	marginLeft: token('space.150', '12px'),
	color: token('color.text.subtlest', N300),
	fontWeight: 500,
	marginTop: 0,
	minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Skeleton = styled(SkeletonSVG)({
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.div<{ color: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ color }) => color || token('color.text.subtlest', N300),
	font: token('font.heading.medium'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
