import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { N400, N30 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

type Props = {
	percentage: number;
	color?: string;
};

const PercentageBar = ({
	percentage,
	color = token('color.background.neutral.bold', N400),
}: Props) => (
	<PercentageBarWrapper>
		<BarWrapper>
			<Bar barColor={color} percentage={percentage} />
		</BarWrapper>
		<PercentageWrapper>{percentage}%</PercentageWrapper>
	</PercentageBarWrapper>
);
export default memo<Props>(PercentageBar);

const BAR_HEIGHT = 12;
const BAR_BORDER_RADIUS = BAR_HEIGHT / 2;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PercentageBarWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BarWrapper = styled.div({
	display: 'flex',
	flex: 'auto',
	height: `${BAR_HEIGHT}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${BAR_BORDER_RADIUS}px`,
	backgroundColor: token('color.background.accent.gray.subtler', N30),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Bar = styled.span<{ percentage: number; barColor: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ barColor }) => barColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${BAR_BORDER_RADIUS}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ percentage }) => (percentage > 0 ? `calc(${percentage}% + ${BAR_HEIGHT}px)` : 0),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PercentageWrapper = styled.span({
	width: '60px',
	textAlign: 'right',
	fontWeight: 500,
});
