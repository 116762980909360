import { useEffect, useMemo } from 'react';
import { usePagination } from '@atlassian/jira-business-pagination/src/index.tsx';
import type { IssuesByType } from '@atlassian/jira-business-summary-services/src/services/types.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import useFetchIssuesByType from '../../services/issues-by-type/index.tsx';
import { useIssuesByType } from '../summary-context/index.tsx';
import type { IssuesByTypeType } from './types.tsx';

const usePagedIssuesByType = (): IssuesByTypeType => {
	const { data: initialData, loading: initialLoading, error: initialError } = useIssuesByType();

	const fetchIssuesByType = useFetchIssuesByType();

	const {
		data: issueTypes,
		error: nextPageError,
		hasNextPage,
		loadingNext,
		fetch,
		fetchNext,
		reset,
		loading,
	} = usePagination<IssuesByType, Error>(fetchIssuesByType);

	// Intentionally not firing exposure here to avoid unnecessary exposure
	const [expConfig] = UNSAFE_noExposureExp('jwm_summary_page_inline_issue_types_change');
	const isInlineIssueTypesEnabled = expConfig.get('isInlineIssueTypesEnabled', false);

	useEffect(() => {
		if (initialData != null) {
			reset({ data: initialData.data, pageInfo: initialData.pageInfo });
		}
	}, [initialData, reset]);

	const data = useMemo(
		() =>
			initialData && issueTypes
				? { issueTypes, totalUnresolvedIssues: initialData.totalUnresolvedIssues }
				: null,
		[issueTypes, initialData],
	);

	return {
		data,
		loading: isInlineIssueTypesEnabled ? initialLoading || loading : initialLoading,
		loadingNext,
		error: initialError || nextPageError,
		hasNextPage,
		fetch,
		fetchNext,
	};
};

export default usePagedIssuesByType;
