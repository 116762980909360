import { useCallback, useEffect } from 'react';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import { usePagination } from '@atlassian/jira-business-pagination/src/index.tsx';
import { fetchActivityFeed as fetchActivityFeedService } from '@atlassian/jira-business-summary-services/src/services/activity-feed/index.tsx';
import type { ActivityFeedItem } from '@atlassian/jira-business-summary-services/src/services/activity-feed/types.tsx';
import { useActivityFeed as useActivityFeedResource } from '@atlassian/jira-router-resources-business-summary-activity-feed/src/index.tsx';
import { useSummaryContext } from '../summary-context/index.tsx';
import type { HookResponseType } from './types.tsx';

const useActivityFeed = (): HookResponseType => {
	const { loading: loadingSummaryData } = useSummaryContext();
	const { data: initialData, loading } = useActivityFeedResource();
	const project = useProject();

	const fetchActivityFeed = useCallback(
		(after: string | null) => fetchActivityFeedService(after, project.key),
		[project],
	);

	const { data, loadingNext, error, hasNextPage, reset, fetchNext } = usePagination<
		ActivityFeedItem,
		Error
	>(fetchActivityFeed, { fetchMode: 'replace' });

	useEffect(() => {
		if (initialData != null) {
			reset(initialData);
		}
	}, [initialData, reset]);

	return {
		data,
		loading: loadingSummaryData || loading,
		loadingNext,
		error,
		hasNextPage,
		fetchNext,
	};
};

export default useActivityFeed;
