import React, { memo } from 'react';
import { N20A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

type Props = {
	className?: string;
	testId: string;
};

const Skeleton = ({ className, testId }: Props) => {
	const skeletonColor = token('color.skeleton', N20A);

	return (
		<svg
			viewBox="0 0 476 296"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={className}
			data-testid={testId}
		>
			<rect width="476" height="8" rx="4" fill={skeletonColor} />
			<rect y="16" width="320" height="8" rx="4" fill={skeletonColor} />
			<rect x="4" y="70" width="32" height="32" rx="16" fill={skeletonColor} />
			<rect x="52" y="60" width="420" height="12" rx="6" fill={skeletonColor} />
			<rect x="52" y="80" width="420" height="12" rx="6" fill={skeletonColor} />
			<rect x="52" y="100" width="93" height="12" rx="6" fill={skeletonColor} />
			<rect x="4" y="162" width="32" height="32" rx="16" fill={skeletonColor} />
			<rect x="52" y="152" width="420" height="12" rx="6" fill={skeletonColor} />
			<rect x="52" y="172" width="420" height="12" rx="6" fill={skeletonColor} />
			<rect x="52" y="192" width="93" height="12" rx="6" fill={skeletonColor} />
			<rect x="4" y="254" width="32" height="32" rx="16" fill={skeletonColor} />
			<rect x="52" y="244" width="420" height="12" rx="6" fill={skeletonColor} />
			<rect x="52" y="264" width="420" height="12" rx="6" fill={skeletonColor} />
			<rect x="52" y="284" width="93" height="12" rx="6" fill={skeletonColor} />
		</svg>
	);
};

export default memo<Props>(Skeleton);
