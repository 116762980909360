import type { ProjectMeta } from './types.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformData = ({ key, lead }: any): ProjectMeta => ({
	key,
	lead: {
		displayName: lead.displayName,
		avatarUrl: lead.avatarUrls['48x48'] ?? '',
		accountId: lead.accountId,
	},
});
