import React, { useCallback, useMemo } from 'react';
import TipsIcon from '@atlaskit/icon/glyph/editor/panel';
import { Box, xcss, Pressable, Text, Flex } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { issueTypesEducationModalEntryPoint } from '@atlassian/jira-issue-types-education-modal/entrypoint.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type IssueTypesHierarchyEducationButtonProps = {
	onClick: () => void;
	testId?: string;
};

export const IssueTypesHierarchyEducationButton = ({
	onClick,
	testId,
}: IssueTypesHierarchyEducationButtonProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onCloseIssueTypesHierarchyEducationModal = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'issueTypesEducationModal',
				action: 'dismissed',
			}),
			'issueTypesEducationModalDismissed',
		);
	}, [createAnalyticsEvent]);

	const entryPointProps = useMemo(
		() => ({
			onClose: onCloseIssueTypesHierarchyEducationModal,
		}),
		[onCloseIssueTypesHierarchyEducationModal],
	);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={issueTypesEducationModalEntryPoint}
			entryPointProps={entryPointProps}
			interactionName="issue-types-hierarchy-education-modal"
			useInternalModal
		>
			{({ ref }) => (
				<Box ref={ref} testId={testId}>
					<Pressable
						onClick={onClick}
						backgroundColor="color.background.neutral.subtle"
						xcss={buttonStyles}
						testId="business-summary.ui.summary-view.types-of-work.add-issue-types.issue-types-hierarchy-education-button"
					>
						<Flex xcss={tipsIconWrapperStyles} alignItems="center" justifyContent="center">
							<TipsIcon label="" />
						</Flex>
						<Text weight="medium" color="color.text.subtle">
							{formatMessage(messages.tipsButtonLabel)}
						</Text>
					</Pressable>
				</Box>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

const tipsIconWrapperStyles = xcss({
	height: '24px',
	width: '24px',
	borderRadius: 'border.radius',
	backgroundColor: 'color.background.neutral',
});

const buttonStyles = xcss({
	padding: 'space.025',
	color: 'color.text.subtlest',
	display: 'flex',
	alignItems: 'center',
	gap: 'space.075',
	justifyContent: 'center',
	':hover': {
		textDecoration: 'underline',
	},
});
