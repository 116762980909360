import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'business-summary.common.card.title',
		defaultMessage: "Something's gone wrong",
		description: "Title of a summary page section's error message",
	},
	description: {
		id: 'business-summary.common.card.description',
		defaultMessage:
			"Looks like we're having trouble connecting to this section. Wait a few moments, then try again.",
		description: "Description of a summary page section's error message",
	},
	refresh: {
		id: 'business-summary.common.card.refresh',
		defaultMessage: 'Refresh',
		description: 'Label of a button allowing to refresh the page.',
	},
	new: {
		id: 'business-summary.common.card.new',
		defaultMessage: 'New',
		description: 'Label indicating the functionality in the card is new to the user.',
	},
});
