import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editActionLabel: {
		id: 'business-summary.common.inline-rename-field.edit-action-label',
		defaultMessage: 'edit name',
		description:
			'Label (used by screen readers) for action to edit field name. The label is appended to the end of the field name value to form a complete label',
	},
});
