import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { useWorkflowsCount } from '../summary-context/index.tsx';

export const useInlineStatusEligibilityCheck = () => {
	const project = useProject();

	const locale = useLocale();
	const isEnglishLocale = locale && locale.startsWith('en');

	const isProjectAdmin = project.permissions.administerProject;
	const isTmp = project.isSimplified;

	const { data: workflowsData, loading, error: workflowsError } = useWorkflowsCount();

	const isEligibleForCreateStatus = isProjectAdmin && isTmp && isEnglishLocale;
	const isEligibleforEditStatus = !(
		loading ||
		workflowsError ||
		!isEnglishLocale ||
		!isProjectAdmin ||
		!isTmp ||
		!workflowsData?.totalCount ||
		workflowsData?.totalCount > 1
	);

	return {
		isEligibleForCreateStatus,
		isEligibleforEditStatus,
	};
};
