import React, { memo, useCallback, useMemo } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import { GridComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import type { SectionProps } from '../../../common/types.tsx';
import AccessibleLink from '../../../common/ui/accessible-link/index.tsx';
import Card from '../../../common/ui/card/index.tsx';
import CreateItemLink from '../../../common/ui/create-item-link/index.tsx';
import { useSectionAnalytics } from '../../../common/utils.tsx';
import { useSummaryFeatures } from '../../../controllers/features-context/index.tsx';
import { useIssuesByPriority } from '../../../controllers/summary-context/index.tsx';
import messages from './messages.tsx';
import { Description, StyledSkeleton } from './styled.tsx';
import { buildChartData } from './utils.tsx';
import XAxis from './x-axis/index.tsx';

echarts.use([BarChart, GridComponent, SVGRenderer]);

const CHART_OPTIONS = { renderer: 'svg' } as const;

const BreakdownByPriority = ({ position }: SectionProps) => {
	const { formatMessage } = useIntl();
	const { data, loading } = useIssuesByPriority();
	const { push } = useRouterActions();
	const { hasCreateItem, linksTarget, issuesLinkUrl } = useSummaryFeatures();

	const { startHoverTimer, stopHoverTimer, fireSectionAnalytics } = useSectionAnalytics(
		position,
		'breakdownByPriority',
	);

	const sendClickAnalytics = useCallback(
		(count: number) => {
			fireSectionAnalytics('viewWithFilter', {
				count,
			});
		},
		[fireSectionAnalytics],
	);

	const chartData = useMemo(
		() => buildChartData(data, formatMessage, issuesLinkUrl),
		[issuesLinkUrl, data, formatMessage],
	);
	const chartEvents = useMemo(
		() => ({
			click: ({ dataIndex, value }: { dataIndex: number; value: number }) => {
				if (chartData != null) {
					// dataIndex can be thought of as the index of each bar on the chart.
					if (linksTarget === '_self') {
						push(chartData.filterUrls[dataIndex]);
					} else {
						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						window.open(chartData.filterUrls[dataIndex], '_blank');
					}
					sendClickAnalytics(value);
				}
			},
		}),
		[chartData, sendClickAnalytics, push, linksTarget],
	);

	let content = null;

	if (!loading && chartData != null) {
		content = (
			<>
				<Description>
					{chartData.totalCount === 0 ? (
						<>
							{formatMessage(messages.descriptionWhenEmpty)}
							{hasCreateItem && (
								<>
									{' '}
									<CreateItemLink
										href={`${issuesLinkUrl}?inlineCreate=true`}
										onClick={() =>
											fireSectionAnalytics('view', {
												inlineCreate: true,
											})
										}
										target={linksTarget}
									/>
								</>
							)}
						</>
					) : (
						<FormattedI18nMessage
							message={formatMessage(messages.description, {
								linkStart: '{linkStart}',
								linkEnd: '{linkEnd}',
							})}
							componentsMapping={{
								link: ({ children }) => (
									<AccessibleLink
										href={`${issuesLinkUrl}?direction=DESC&sortBy=priority`}
										target={linksTarget}
										onClick={() =>
											fireSectionAnalytics('view', {
												inlineCreate: false,
											})
										}
									>
										{children}
									</AccessibleLink>
								),
							}}
						/>
					)}
				</Description>

				<ReactEChartsCore
					echarts={echarts}
					option={chartData.option}
					opts={CHART_OPTIONS}
					onEvents={chartEvents}
				/>

				<XAxis points={chartData.xAxisPoints} onClick={sendClickAnalytics} />
			</>
		);
	} else {
		content = (
			<UFOLoadHold name="breakdown-by-priority">
				<StyledSkeleton testId="business-summary.ui.summary-view.breakdown-by-priority.skeleton" />
			</UFOLoadHold>
		);
	}

	return (
		<UFOSegment name="business-summary-breakdown-by-priority">
			<Card
				title={formatMessage(messages.title)}
				isLoading={loading}
				testId="business-summary.ui.summary-view.breakdown-by-priority"
				onPointerEnter={startHoverTimer}
				onPointerLeave={stopHoverTimer}
			>
				{content}
			</Card>
		</UFOSegment>
	);
};

export default memo<SectionProps>(BreakdownByPriority);
