import React, { memo, useEffect } from 'react';
import { styled } from '@compiled/react';
import { xcss, Box } from '@atlaskit/primitives';
import { BusinessDocumentTitle } from '@atlassian/jira-business-entity-common/src/utils/document-title/index.tsx';
import { ErrorPage } from '@atlassian/jira-business-error-page/src/index.tsx';
import {
	ExperienceErrorBoundary,
	ExperienceSuccess,
	ExperienceFailed,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { REQUEST_START, REQUEST_END } from '@atlassian/jira-business-performance/src/constants.tsx';
import {
	PageLoadMetrics,
	RenderStartMark,
} from '@atlassian/jira-business-performance/src/ui/page-load/index.tsx';
import { markViewMetric } from '@atlassian/jira-business-performance/src/ui/page-load/utils.tsx';
import { JWMSpaStatePageReady } from '@atlassian/jira-business-spa-state-page-ready/src/ui/index.tsx';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	MAIN_EXPERIENCE,
	ACTIVITY_FEED_EXPERIENCE,
	RELATED_PROJECTS_EXPERIENCE,
	LARGE_SCREEN_WIDTH,
} from '../../common/constants.tsx';
import { ErrorCard } from '../../common/ui/card/index.tsx';
import ResponsiveGrid from '../../common/ui/responsive-grid/index.tsx';
import { useSummaryFeatures } from '../../controllers/features-context/index.tsx';
import { useSummaryContext } from '../../controllers/summary-context/index.tsx';
import ActivityFeed from './activity-feed/index.tsx';
import BreakdownByPriority from './breakdown-by-priority/index.tsx';
import FeedbackBanner from './feedback-banner/index.tsx';
import SummaryHeader from './header/index.tsx';
import messages from './messages.tsx';
import OverviewItems from './overview-items/index.tsx';
import ProgressByStatus from './progress-by-status/index.tsx';
import ProjectDetails from './project-details/index.tsx';
import RelatedProjects from './related-projects/index.tsx';
import TypesOfWork from './types-of-work/index.tsx';
import WorkloadByAssignee from './workload-by-assignee/index.tsx';

const cardFallback = () => <ErrorCard />;

const SummaryView = () => {
	const { formatMessage } = useIntl();
	const { featureView, hasFeedbackFooter, hasHeader } = useSummaryFeatures();

	const { data, loading, error } = useSummaryContext();
	const isReady = data != null && !loading && !error;

	useEffect(() => {
		if (loading) {
			markViewMetric('summary', REQUEST_START);
		} else {
			markViewMetric('summary', REQUEST_END);
		}
	}, [loading]);

	const pageHeading = formatMessage(messages.summaryHeading);

	if (error != null) {
		return (
			<>
				<ErrorPage error={error} />
				<ExperienceFailed experience={MAIN_EXPERIENCE} error={error} />
			</>
		);
	}

	const header = (
		<Box xcss={HeaderWrapperStyles}>
			{hasHeader && <SummaryHeader isLoading={loading} />}

			<ProjectDetails isSkeleton={loading} />
		</Box>
	);

	let position = 0;

	// eslint-disable-next-line no-return-assign
	return (
		<>
			<RenderStartMark view="summary" loading={loading} />
			<BusinessDocumentTitle pageHeading={pageHeading} />
			<ResponsiveContainer>
				{header}
				<OverviewItems />
				<ResponsiveGrid>
					<ProgressByStatus position={(position += 1)} />

					<ExperienceErrorBoundary
						experience={ACTIVITY_FEED_EXPERIENCE}
						shouldStartExperience
						fallback={cardFallback}
					>
						<ActivityFeed position={(position += 1)} />
					</ExperienceErrorBoundary>

					<BreakdownByPriority position={(position += 1)} />

					<TypesOfWork position={(position += 1)} />

					<WorkloadByAssignee position={(position += 1)} />

					<ExperienceErrorBoundary
						experience={RELATED_PROJECTS_EXPERIENCE}
						shouldStartExperience
						fallback={cardFallback}
					>
						<RelatedProjects position={(position += 1)} />
					</ExperienceErrorBoundary>
				</ResponsiveGrid>

				{hasFeedbackFooter && (
					<footer>
						<FeedbackBanner isLoading={loading} />
					</footer>
				)}
			</ResponsiveContainer>

			{isReady && (
				<>
					<PageLoadMetrics view={featureView} />
					<ExperienceSuccess experience={MAIN_EXPERIENCE} />
				</>
			)}
			{!loading && <JWMSpaStatePageReady />}
		</>
	);
};

export default memo<{}>(SummaryView);

const HeaderWrapperStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: Tokens.ELEVATION_SURFACE_CONTRAST,
	borderRadius: 'border.radius.200',
	paddingLeft: 'space.400',
	paddingRight: 'space.400',
	paddingTop: 'space.300',
	paddingBottom: 'space.100',
	marginTop: 'space.200',
	marginBottom: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResponsiveContainer = styled.div({
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values,@atlaskit/ui-styling-standard/no-unsafe-values
	maxWidth: `${LARGE_SCREEN_WIDTH}px`,
	alignSelf: 'center',
});
