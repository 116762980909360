import React, { type ReactNode, createContext, useContext, useMemo } from 'react';
import type { JiraProject } from '@atlassian/jira-business-entity-common/src/types.tsx';
import { PROJECT_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { BUSINESS_FIELD_TYPES } from '../../common/constants.tsx';
import type { TransformedFieldData, AvatarOption } from '../../common/types.tsx';

type ContextValue = {
	assigneeQuery: string;
	projectFilter: TransformedFieldData<AvatarOption>;
};

const DEFAULT_VALUE = {
	assigneeQuery: '',
	projectFilter: {
		totalCount: 0,
		options: [],
	},
};

const FiltersContext = createContext<ContextValue>(DEFAULT_VALUE);

export type ProviderProps = {
	children: ReactNode;
	projects?: JiraProject[];
};

export const FiltersProvider = ({ projects, children }: ProviderProps) => {
	const value = useMemo(() => {
		if (!projects) {
			return DEFAULT_VALUE;
		}

		const assigneeQuery = `is assignee of (${projects.map((project) => project.key).join(', ')})`;

		const projectFilter = {
			totalCount: projects.length,
			options: projects.map((project) => ({
				label: project.name,
				value: String(project.id),
				avatar: project.avatarUrl,
				square: true,
			})),
		};

		return {
			assigneeQuery,
			projectFilter,
		};
	}, [projects]);

	return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};

export const useAssigneesFilterQuery = (): string => useContext(FiltersContext).assigneeQuery;

export const useProjectFilter = (): TransformedFieldData<AvatarOption> =>
	useContext(FiltersContext).projectFilter;

export const useAllowedFieldTypes = (inputAllowedFieldTypes?: Set<string>) => {
	const filtersContext = useContext(FiltersContext);

	return useMemo(() => {
		const result = new Set(inputAllowedFieldTypes ?? BUSINESS_FIELD_TYPES);

		if (filtersContext.projectFilter.totalCount <= 1) {
			result.delete(PROJECT_TYPE);
		}

		return result;
	}, [filtersContext, inputAllowedFieldTypes]);
};
