import React, { memo } from 'react';
import { N20A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

type Props = {
	className?: string;
	testId: string;
};

const Skeleton = ({ className, testId }: Props) => {
	const skeletonColor = token('color.skeleton', N20A);

	return (
		<svg
			viewBox="0 0 476 296"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={className}
			data-testid={testId}
		>
			<rect width="476" height="8" rx="4" fill={skeletonColor} />
			<rect y="16" width="320" height="8" rx="4" fill={skeletonColor} />
			<rect y="64" width="24" height="24" rx="4" fill={skeletonColor} />
			<rect x="32" y="68" width="93" height="16" rx="8" fill={skeletonColor} />
			<rect x="141" y="68" width="265" height="16" rx="8" fill={skeletonColor} />
			<rect x="422" y="68" width="52" height="16" rx="8" fill={skeletonColor} />
			<rect y="116" width="24" height="24" rx="4" fill={skeletonColor} />
			<rect x="32" y="120" width="93" height="16" rx="8" fill={skeletonColor} />
			<rect x="141" y="120" width="265" height="16" rx="8" fill={skeletonColor} />
			<rect x="422" y="120" width="52" height="16" rx="8" fill={skeletonColor} />
			<rect y="168" width="24" height="24" rx="4" fill={skeletonColor} />
			<rect x="32" y="172" width="93" height="16" rx="8" fill={skeletonColor} />
			<rect x="141" y="172" width="265" height="16" rx="8" fill={skeletonColor} />
			<rect x="422" y="172" width="52" height="16" rx="8" fill={skeletonColor} />
			<rect y="216" width="24" height="24" rx="4" fill={skeletonColor} />
			<rect x="32" y="220" width="93" height="16" rx="8" fill={skeletonColor} />
			<rect x="141" y="220" width="265" height="16" rx="8" fill={skeletonColor} />
			<rect x="422" y="220" width="52" height="16" rx="8" fill={skeletonColor} />
			<rect y="260" width="24" height="24" rx="4" fill={skeletonColor} />
			<rect x="32" y="264" width="93" height="16" rx="8" fill={skeletonColor} />
			<rect x="141" y="264" width="265" height="16" rx="8" fill={skeletonColor} />
			<rect x="422" y="264" width="52" height="16" rx="8" fill={skeletonColor} />
			<rect y="304" width="24" height="24" rx="4" fill={skeletonColor} />
			<rect x="32" y="308" width="93" height="16" rx="8" fill={skeletonColor} />
			<rect x="141" y="308" width="265" height="16" rx="8" fill={skeletonColor} />
			<rect x="422" y="308" width="52" height="16" rx="8" fill={skeletonColor} />
		</svg>
	);
};

export default memo<Props>(Skeleton);
