export const ISSUE_LAYOUT_CONFIGURATION_QUERY = `
  query JiraBusinessIssueLayoutConfigurationData ($projectId: Long!, $extraDefinerId: Long!){
  issueLayoutConfiguration(
    issueLayoutKey: {projectId: $projectId, extraDefinerId: $extraDefinerId}
    type: ISSUE_VIEW
  ) {
    ... on JiraIssueLayoutConfigurationResult {
      issueLayoutResult {
        id
        name
        usageInfo {
          ... on JiraIssueLayoutUsageInfoConnection {
            edges {
              currentProject
              node {
                layoutOwners {
                  ... on JiraIssueLayoutIssueTypeOwner {
                    description
                    avatarId
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
