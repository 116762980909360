import React from 'react';
import EditorHorizontalRuleIcon from '@atlaskit/icon/glyph/editor/horizontal-rule';
import EditorMoreIcon from '@atlaskit/icon/glyph/editor/more';
import {
	B100,
	B300,
	G300,
	N30,
	N100,
	N200,
	R200,
	R300,
	R400,
	T300,
	P300,
	Y300,
} from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { OPEN_ISSUES_JQL } from '@atlassian/jira-business-summary-services/src/services/constants.tsx';
import type {
	IssueByPriorityDataType,
	IssuesByPriorityDataType,
} from '@atlassian/jira-business-summary-services/src/services/summary-data/types.tsx';
import { fontFamily } from '@atlassian/jira-common-styles/src/main.tsx';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { Y_AXIS_WIDTH } from './constants.tsx';
import messages from './messages.tsx';
import type { XAxisDataPoint, YAxisDataPoint } from './types.tsx';

const KNOWN_PRIORITY_BAR_COLORS: Record<string, string> = {
	Highest: R400,
	High: R200,
	Medium: Y300,
	Low: B100,
	Lowest: B300,
	None: N100,
};

const OTHER_BAR_COLORS = [G300, T300, P300, R300];

const MAX_BARS = 8;

const createFilterUrlFromPriorities = (issuesLinkUrl: string, ...priorities: string[]): string => {
	if (priorities.length === 0) {
		return '';
	}

	const escapedPriorities = priorities.map((priority) =>
		priority == null ? 'EMPTY' : `'${priority.split("'").join("\\'")}'`,
	);

	let filter;
	if (priorities.length === 1) {
		filter = encodeURIComponent(`${OPEN_ISSUES_JQL} AND priority = ${escapedPriorities[0]}`);
	} else {
		filter = encodeURIComponent(
			`${OPEN_ISSUES_JQL} AND priority in (${escapedPriorities.join(',')})`,
		);
	}

	return `${issuesLinkUrl}?filter=${filter}`;
};

const createYAxisPoint = (value: number, color: string) => ({
	itemStyle: { color },
	value,
});

export const buildChartData = (
	priorityGroupData: IssuesByPriorityDataType | null | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	formatMessage: (messageDescriptor: MessageDescriptor, values?: any) => string,
	issuesLinkUrl: string,
) => {
	if (priorityGroupData == null) {
		return null;
	}

	const option = {
		// The positioning for the chart grid is quite generous, so override to allow the chart to take up more space.
		grid: { left: Y_AXIS_WIDTH, right: 0, top: 30, bottom: 10 },
		xAxis: {
			axisLabel: {
				interval: 0,
				show: false,
			},
			axisLine: {
				show: false,
			},
			axisTick: {
				// Removes the tick lines between each category.
				show: false,
			},
			data: [],
			type: 'category',
		},
		yAxis: {
			axisLabel: {
				color: token('color.text.subtlest', N100),
				fontFamily,
			},
			max: undefined,
			min: undefined,
			minInterval: 1,
			splitLine: {
				lineStyle: {
					color: token('color.border.disabled', N30),
					width: 2,
				},
			},
			type: 'value',
		},
		series: [
			{
				animation: false,
				barWidth: '50%',
				data: [],
				type: 'bar',
				// Override the default hover/emphasis styling on bars to be more subtle.
				emphasis: { itemStyle: { color: 'inherit', opacity: 0.9 } },
			},
		],
	};
	const filterUrls: Array<string> = [];
	const xAxisPoints: XAxisDataPoint[] = [];
	const yAxisPoints: YAxisDataPoint[] = [];

	let colors = [...OTHER_BAR_COLORS];
	let totalCount = 0;

	let priorityGroups;
	let otherGroups;

	if (priorityGroupData.length <= MAX_BARS) {
		priorityGroups = priorityGroupData;
	} else {
		priorityGroups = priorityGroupData.slice(0, MAX_BARS - 1);
		otherGroups = priorityGroupData.slice(MAX_BARS - 1);
	}

	priorityGroups.forEach((priorityGroup: IssueByPriorityDataType) => {
		const {
			count,
			priority: { iconUrl, name },
		} = priorityGroup;

		if (colors.length === 0) {
			colors = [...OTHER_BAR_COLORS];
		}

		// The filterUrls are pushed in the order that the bars are added.
		// This means we can use the index of each bar to get the filterLink for that bar.
		const filterUrl = createFilterUrlFromPriorities(issuesLinkUrl, name);
		filterUrls.push(filterUrl);

		// When issues don't have a priority, they come back in a group where the priority name and icon is null.
		// In this case we have a specific label and icon to show.
		const xAxisName = name || formatMessage(messages.noPriorityLabel);
		const noPriorityIcon = (
			<EditorHorizontalRuleIcon
				label=""
				size="medium"
				primaryColor={token('color.text.subtle', '#7A869A')}
			/>
		);

		const xAxisPointIcon = iconUrl || noPriorityIcon;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const barColor = KNOWN_PRIORITY_BAR_COLORS[xAxisName] || (colors.pop() as string);

		xAxisPoints.push({
			icon: xAxisPointIcon,
			filterUrl,
			name: xAxisName,
			count,
		});
		yAxisPoints.push(createYAxisPoint(count, barColor));

		totalCount += count;
	});

	if (otherGroups) {
		const xAxisName = formatMessage(messages.morePrioritiesLabel);
		const barColor = N200;

		const otherCount = otherGroups.reduce(
			(countSoFar: number, { count }: { count: number }) => countSoFar + count,
			0,
		);
		const priorities = otherGroups.map(
			({ priority: { name } }: { priority: { name: string } }) => name,
		);

		const filterUrl = createFilterUrlFromPriorities(issuesLinkUrl, ...priorities);
		filterUrls.push(filterUrl);

		xAxisPoints.push({
			icon: (
				<EditorMoreIcon
					label=""
					size="medium"
					primaryColor={token('color.text.subtlest', '#6b778c')}
				/>
			),
			filterUrl,
			name: xAxisName,
			count: otherCount,
		});
		yAxisPoints.push(createYAxisPoint(otherCount, barColor));

		totalCount += otherCount;
	}

	if (totalCount === 0) {
		const { yAxis } = option;
		// Set a min and max so that the y axis doesn't default to only showing 0 and 1 split lines.
		// @ts-expect-error - TS2322 - Type '0' is not assignable to type 'undefined'.
		yAxis.min = 0;
		// @ts-expect-error - TS2322 - Type '5' is not assignable to type 'undefined'.
		yAxis.max = 5;
	}

	// We only have one series for this bar chart, so its safe to always mutate the data on the first item.
	// @ts-expect-error - TS2322 - Type 'YAxisDataPoint[]' is not assignable to type 'never[]'.
	option.series[0].data = yAxisPoints;

	return { filterUrls, option, totalCount, xAxisPoints };
};
