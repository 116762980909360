import React, { memo, type SyntheticEvent } from 'react';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import { N100 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import { getIssueTypesSettingsUrl } from '@atlassian/jira-business-issue-types/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useSummaryFeatures } from '../../../../controllers/features-context/index.tsx';
import messages from './messages.tsx';
import { StyledButton, IconWrapper } from './styled.tsx';

type Props = {
	onClick?: (event: SyntheticEvent) => void;
};

const ManageTypesButton = ({ onClick }: Props) => {
	const { formatMessage } = useIntl();
	const project = useProject();
	const projectKey = project.key;
	const { linksTarget } = useSummaryFeatures();

	const href = getIssueTypesSettingsUrl(projectKey, project.isSimplified);

	const icon = (
		<IconWrapper>
			<SettingsIcon label="" size="small" primaryColor={token('color.icon.subtle', N100)} />
		</IconWrapper>
	);

	return (
		<StyledButton
			href={href}
			target={linksTarget}
			onClick={onClick}
			iconBefore={icon}
			appearance="subtle-link"
			testId="business-summary.ui.summary-view.types-of-work.manage-types-button"
		>
			{formatMessage(messages.manageTypes)}
		</StyledButton>
	);
};

export default memo<Props>(ManageTypesButton);
