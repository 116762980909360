import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import type NotFound from './main.tsx';

const NotFoundComponent = lazyAfterPaint<typeof NotFound>(
	() => import(/* webpackChunkName: "async-jwm-project-not-found" */ './main'),
);

const NotFoundAsync = () => (
	<Placeholder name="not-found-component" fallback={null}>
		<NotFoundComponent />
	</Placeholder>
);

export default NotFoundAsync;
