import {
  JiraIssueStatusAriResourceOwner,
  JiraIssueStatusAriResourceType
} from "./chunk-S3WTNWJL.mjs";
import {
  RegisteredAri
} from "./chunk-CLQNXEWF.mjs";
import {
  AriParser
} from "./chunk-HNZGDVUS.mjs";

// src/jira/issue-status/manifest.ts
var jiraIssueStatusAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9-]+$"),
  resourceOwner: JiraIssueStatusAriResourceOwner,
  resourceType: JiraIssueStatusAriResourceType,
  resourceIdSlug: "activation/{activationId}/{issuestatusId}",
  resourceIdSegmentFormats: {
    activationId: /[a-zA-Z0-9\-]+/,
    // eslint-disable-line no-useless-escape
    issuestatusId: /[!a-zA-Z0-9\-_.~@:{}=]+(\/[!a-zA-Z0-9\-_.~@:{}=]+)*/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira/issue-status/index.ts
var JiraIssueStatusAri = class _JiraIssueStatusAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._activationId = opts.resourceIdSegmentValues.activationId, this._issuestatusId = opts.resourceIdSegmentValues.issuestatusId;
  }
  get siteId() {
    return this._siteId;
  }
  get activationId() {
    return this._activationId;
  }
  get issuestatusId() {
    return this._issuestatusId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraIssueStatusAriStaticOpts.qualifier,
      platformQualifier: jiraIssueStatusAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraIssueStatusAriStaticOpts.resourceOwner,
      resourceType: jiraIssueStatusAriStaticOpts.resourceType,
      resourceId: `activation/${opts.activationId}/${opts.issuestatusId}`,
      resourceIdSegmentValues: {
        activationId: opts.activationId,
        issuestatusId: opts.issuestatusId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraIssueStatusAriStaticOpts);
    return new _JiraIssueStatusAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraIssueStatusAriStaticOpts);
    return new _JiraIssueStatusAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      activationId: this.activationId,
      issuestatusId: this.issuestatusId
    };
  }
};

export {
  JiraIssueStatusAri
};
