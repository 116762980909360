import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	projectLead: {
		id: 'business-summary.summary-view.project-details.content.project-lead',
		defaultMessage: 'Project lead',
		description: 'Label describing the title of the project lead.',
	},
	projectKey: {
		id: 'business-summary.summary-view.project-details.content.project-key',
		defaultMessage: 'Project key',
		description: 'Title of the section that contains the project key.',
	},
	errorMessage: {
		id: 'business-summary.summary-view.project-details.content.error-message',
		defaultMessage: 'Could not retrieve project details.',
		description: 'Error message when project details could not be retrieved.',
	},
});
