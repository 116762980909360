import React, { useMemo, useCallback } from 'react';
import AddIcon from '@atlaskit/icon/utility/add';
import { xcss, Inline, Pressable, Flex, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import { useCreateStatus } from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/create-status/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { asyncInlineCreateStatusModal } from '@atlassian/jira-inline-create-status-modal/entrypoint.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { defaultModalProps } from '@atlassian/jira-issue-status-and-types-common/src/index.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { CREATE_STATUS_EXPERIENCE } from '../../../../common/constants.tsx';
import messages from './messages.tsx';
import type { CreateNewStatus, CreateStatusButtonProps } from './types.tsx';

export const CreateStatusButton = ({
	onClick,
	statusNames,
	onCreateStatusSuccess,
	hasMultipleWorkflows,
}: CreateStatusButtonProps) => {
	const { formatMessage } = useIntl();
	const createStatus = useCreateStatus(CREATE_STATUS_EXPERIENCE);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cloudId = useCloudId();
	const { id: projectId, key: projectKey, type: projectType } = useProject();

	const onCreateStatus = useCallback(
		async (payload: CreateNewStatus) => {
			await createStatus(payload);
			onCreateStatusSuccess?.();
		},
		[createStatus, onCreateStatusSuccess],
	);

	const onCloseCreateStatusModal = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'createStatusModal',
				action: 'dismissed',
			}),
			'createStatusModalDismissed',
		);
	}, [createAnalyticsEvent]);

	const entryPointParams = useMemo(
		() => ({
			cloudId,
			projectKey,
		}),
		[cloudId, projectKey],
	);

	const entryPointProps = useMemo(
		() => ({
			statusNames,
			hasMultipleWorkflows,
			projectId: projectId.toString(),
			projectKey,
			projectType,
			onCreateStatus,
			onClose: onCloseCreateStatusModal,
			isNewControllerEnabled: fg('jira_inline_status_new_controllers'),
			workflowAssociatedIssueTypeId: null,
			onStatusCreated: onCreateStatusSuccess,
		}),
		[
			statusNames,
			hasMultipleWorkflows,
			projectId,
			projectKey,
			projectType,
			onCreateStatus,
			onCloseCreateStatusModal,
			onCreateStatusSuccess,
		],
	);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={asyncInlineCreateStatusModal}
			entryPointProps={entryPointProps}
			entryPointParams={entryPointParams}
			interactionName="create-status-modal"
			modalProps={defaultModalProps}
			useInternalModal
		>
			{({ ref }) => (
				<Inline
					xcss={dividerStyles}
					testId="business-summary.ui.summary-view.progress-by-status.create-status-button"
				>
					<Pressable
						onClick={onClick}
						backgroundColor="color.background.neutral.subtle"
						xcss={buttonStyles}
						// ref returned from entrypoint is of Type 'RefObject<HTMLElement>' is not assignable to type 'Ref<HTMLButtonElement>
						// and hence a type assertion is needed to pass the ref to button element
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						ref={ref as React.Ref<HTMLButtonElement>}
					>
						<Flex xcss={addIconWrapperStyles} alignItems="center" justifyContent="center">
							<AddIcon label="" color={token('color.icon')} />
						</Flex>
						<Text weight="medium" color="color.text.subtle">
							{formatMessage(messages.createStatus)}
						</Text>
					</Pressable>
				</Inline>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

const addIconWrapperStyles = xcss({
	height: '24px',
	width: '24px',
	borderRadius: 'border.radius',
	backgroundColor: 'color.background.neutral',
});

const dividerStyles = xcss({
	borderTopColor: 'color.border',
	borderTopStyle: 'solid',
	borderTopWidth: '1px',
	paddingTop: 'space.100',
	marginTop: 'space.100',
	marginBottom: 'space.100',
	marginLeft: 'space.300',
});

const buttonStyles = xcss({
	padding: 'space.025',
	color: 'color.text.subtlest',
	display: 'flex',
	alignItems: 'center',
	gap: 'space.075',
	justifyContent: 'center',
	':hover': {
		textDecoration: 'underline',
	},
});
