import { useCallback, useMemo, type SyntheticEvent } from 'react';
import throttle from 'lodash/throttle';

const DEFAULT_ON_END_REACHED_THRESHOLD = 300;
const DEFAULT_THROTTLE_TIME = 16;

export type ScrollToEndOptions = {
	onEndReachedThreshold?: number;
	throttleTime?: number;
};

export type ScrollToEndProps = {
	isDisabled?: boolean;
	options?: ScrollToEndOptions;
	onScrollToEnd: () => void;
};

export type OnScroll = (event: SyntheticEvent<HTMLElement>) => void;

export const useScrollToEnd = ({ onScrollToEnd, isDisabled, options }: ScrollToEndProps) => {
	const onEndReachedThreshold = options?.onEndReachedThreshold ?? DEFAULT_ON_END_REACHED_THRESHOLD;
	const throttleTime = options?.throttleTime ?? DEFAULT_THROTTLE_TIME;

	const calculateEndReached = useMemo(
		() =>
			throttle(({ clientHeight, scrollTop, scrollHeight }) => {
				const distanceFromEnd = scrollHeight - scrollTop - clientHeight;
				if (distanceFromEnd < onEndReachedThreshold && isDisabled !== true) {
					onScrollToEnd();
				}
			}, throttleTime),
		[onScrollToEnd, throttleTime, isDisabled, onEndReachedThreshold],
	);

	const onScroll: OnScroll = useCallback(
		(event) => {
			const { clientHeight, scrollHeight, scrollTop } = event.currentTarget;
			calculateEndReached({ clientHeight, scrollHeight, scrollTop });
		},
		[calculateEndReached],
	);

	return {
		onScroll,
	};
};
