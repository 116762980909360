import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	shareTitle: {
		id: 'business-summary.summary-view.header.share-button.share-title',
		defaultMessage: 'Summary',
		description:
			'Name of the entity type that is going to be shared to others, this will be used as email title for the share recipients',
	},
});
